import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
   * `DateTimeOffset` expects timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 **/
  DateTimeOffset: any,
  /** 
 * The `DateTime` scalar type represents a date and time. `DateTime` expects
   * timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 **/
  DateTime: any,
  /** 
 * The `Date` scalar type represents a year, month and day in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 **/
  Date: any,
  Decimal: any,
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any,
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any,
};

export type AddOrganizationPaymentMethodInput = {
  organizationId?: Maybe<Scalars['ID']>,
  externalId: Scalars['String'],
  isDefault: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
};

export type AddOrganizationPaymentMethodPayload = {
   __typename?: 'AddOrganizationPaymentMethodPayload',
  organization?: Maybe<Organization>,
  result?: Maybe<Scalars['Boolean']>,
};

/** Represents a street address, including geographical information */
export type Address = {
   __typename?: 'Address',
  /** Address line 1 */
  addressLine1: Scalars['String'],
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']>,
  /** Address line 3 */
  addressLine3?: Maybe<Scalars['String']>,
  /** City */
  city: City,
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Original json data received from geocoding service */
  jsonData?: Maybe<Scalars['String']>,
  /** Location */
  location?: Maybe<Location>,
  /** Postal code */
  postalcode: Scalars['String'],
  /** Postal code */
  streetNumber?: Maybe<Scalars['String']>,
};

/** Represents a street address, including geographical information */
export type AddressInput = {
  /** Address id */
  id?: Maybe<Scalars['ID']>,
  /** City */
  city: CityInput,
  /** Postal code */
  postalcode: Scalars['String'],
  /** Postal code */
  streetNumber?: Maybe<Scalars['String']>,
  /** Original json data received from geo coding service */
  jsonData?: Maybe<Scalars['String']>,
  /** Location */
  location?: Maybe<LocationInput>,
  /** Address line 1 */
  addressLine1: Scalars['String'],
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']>,
  /** Address line 3 */
  addressLine3?: Maybe<Scalars['String']>,
};

/** Represents a street address, including geographical information */
export type AddressPayload = {
   __typename?: 'AddressPayload',
  address: Address,
};

/** Version information regarding the current running system components. */
export type ApplicationInformation = {
   __typename?: 'ApplicationInformation',
  /** Informational version containing hash and build id. */
  assemblyInformationalVersion?: Maybe<Scalars['String']>,
  /** Assembly version of the executing assembly. */
  assemblyVersion?: Maybe<Scalars['String']>,
  /** List of currently loaded assemblies in the appdomain. */
  loadedAssemblies?: Maybe<Array<Maybe<Scalars['String']>>>,
  /** Name of the application. */
  name?: Maybe<Scalars['String']>,
};

/** Represents a file asset */
export type Asset = {
   __typename?: 'Asset',
  assetType?: Maybe<AssetType>,
  displayName?: Maybe<Scalars['String']>,
  filename: Scalars['String'],
  /** Asset id */
  id?: Maybe<Scalars['ID']>,
  lastShortTermReadToken?: Maybe<Scalars['String']>,
  lastShortTermWriteToken?: Maybe<Scalars['String']>,
  mimeType: Scalars['String'],
  /** Owning Organization */
  organization?: Maybe<Organization>,
  size: Scalars['Int'],
};

/** Represents a file asset */
export type AssetInput = {
  /** Asset id */
  id?: Maybe<Scalars['ID']>,
  filename: Scalars['String'],
  size: Scalars['Int'],
  assetType?: Maybe<AssetType>,
  displayName?: Maybe<Scalars['String']>,
  mimeType: Scalars['String'],
};

export type AssetQueryInput = {
  filenames?: Maybe<Array<Maybe<Scalars['String']>>>,
  displayNames?: Maybe<Array<Maybe<Scalars['String']>>>,
  mimeTypes?: Maybe<Array<Maybe<Scalars['String']>>>,
  ownedBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  assetTypes?: Maybe<Array<Maybe<AssetType>>>,
  sizeFrom?: Maybe<Scalars['Int']>,
  sizeTo?: Maybe<Scalars['Int']>,
  createdFrom?: Maybe<Scalars['DateTimeOffset']>,
  createdTo?: Maybe<Scalars['DateTimeOffset']>,
  updatedFrom?: Maybe<Scalars['DateTimeOffset']>,
  updatedTo?: Maybe<Scalars['DateTimeOffset']>,
};

export enum AssetType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Data = 'DATA',
  ResidentRegistry = 'RESIDENT_REGISTRY',
  HandlebarsTemplate = 'HANDLEBARS_TEMPLATE',
  WeatherIcons = 'WEATHER_ICONS'
}

export type AssignComponentAssetInput = {
  componentId: Scalars['ID'],
  assetId: Scalars['ID'],
};

export type AssignComponentAssetPayload = {
   __typename?: 'AssignComponentAssetPayload',
  asset?: Maybe<Asset>,
  component?: Maybe<Component>,
  result?: Maybe<Scalars['Boolean']>,
};

export type AssignComponentDataSourceInput = {
  componentId: Scalars['ID'],
  dataSourceId: Scalars['ID'],
};

export type AssignComponentDataSourcePayload = {
   __typename?: 'AssignComponentDataSourcePayload',
  component?: Maybe<Component>,
  dataSource?: Maybe<DataSource>,
  result?: Maybe<Scalars['Boolean']>,
};

export type AssignDataSourceAssetInput = {
  dataSourceId: Scalars['ID'],
  assetId: Scalars['ID'],
};

export type AssignDataSourceAssetPayload = {
   __typename?: 'AssignDataSourceAssetPayload',
  asset: Asset,
  dataSource: DataSource,
  result?: Maybe<Scalars['Boolean']>,
};

export type AssignDeviceAddressInput = {
  deviceId: Scalars['ID'],
  addressId: Scalars['ID'],
};

export type AssignDeviceAddressPayload = {
   __typename?: 'AssignDeviceAddressPayload',
  address: Address,
  device: Device,
  result?: Maybe<Scalars['Boolean']>,
};

export type AssignDeviceControllerInput = {
  deviceId: Scalars['ID'],
  controllerId: Scalars['ID'],
};

export type AssignDevicePageInput = {
  deviceId: Scalars['ID'],
  pageId: Scalars['ID'],
};

export type AssignPageComponentInput = {
  componentId: Scalars['ID'],
  pageId: Scalars['ID'],
};

export type AssignPageComponentPayload = {
   __typename?: 'AssignPageComponentPayload',
  component: Component,
  page: Page,
  result?: Maybe<Scalars['Boolean']>,
};

/** Represents an audit entry */
export type AuditEntry = {
   __typename?: 'AuditEntry',
  auditEntryId: Scalars['Int'],
  createdBy: Scalars['String'],
  createdDate: Scalars['DateTimeOffset'],
  entitySetName: Scalars['String'],
  entityTypeName: Scalars['String'],
  properties?: Maybe<Array<Maybe<AuditEntryProperty>>>,
  state?: Maybe<AuditEntryState>,
  stateName: Scalars['String'],
};

/** Represents an audit entry property */
export type AuditEntryProperty = {
   __typename?: 'AuditEntryProperty',
  auditEntryPropertyID: Scalars['Int'],
  newValue?: Maybe<Scalars['String']>,
  oldValue?: Maybe<Scalars['String']>,
  propertyName?: Maybe<Scalars['String']>,
  relationName?: Maybe<Scalars['String']>,
};

export type AuditEntryQueryInput = {
  entitySetNames?: Maybe<Array<Maybe<Scalars['String']>>>,
  entityTypeNames?: Maybe<Array<Maybe<Scalars['String']>>>,
  excludeEntityTypeNames?: Maybe<Array<Maybe<Scalars['String']>>>,
  entityIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  states?: Maybe<Array<Maybe<AuditEntryState>>>,
  createdBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  from?: Maybe<Scalars['DateTimeOffset']>,
  to?: Maybe<Scalars['DateTimeOffset']>,
};

export enum AuditEntryState {
  EntityAdded = 'ENTITY_ADDED',
  EntityDeleted = 'ENTITY_DELETED',
  EntityModified = 'ENTITY_MODIFIED',
  EntitySoftAdded = 'ENTITY_SOFT_ADDED',
  EntitySoftDeleted = 'ENTITY_SOFT_DELETED',
  RelationshipAdded = 'RELATIONSHIP_ADDED',
  RelationshipDeleted = 'RELATIONSHIP_DELETED',
  EntityCurrent = 'ENTITY_CURRENT'
}

export type CancelOrganizationSubscriptionInput = {
  organizationId?: Maybe<Scalars['ID']>,
  subscriptionId?: Maybe<Scalars['ID']>,
};

export type CancelOrganizationSubscriptionPayload = {
   __typename?: 'CancelOrganizationSubscriptionPayload',
  result?: Maybe<Scalars['Boolean']>,
  subscription?: Maybe<OrganizationSubscription>,
};

/** City information including geographical location */
export type City = {
   __typename?: 'City',
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Location */
  location?: Maybe<Location>,
  /** Name of city */
  name: Scalars['String'],
  /** Subdivision (area/state) */
  subdivision: Subdivision,
};

/** City information including geographical location */
export type CityInput = {
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Name of city */
  name: Scalars['String'],
  /** Subdivision (area/state) */
  subdivision: SubdivisionInput,
  /** Location */
  location?: Maybe<LocationInput>,
};

/** A Security Claim. */
export type Claim = {
   __typename?: 'Claim',
  issuer?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

/** A Principal With Claims */
export type ClaimsPrincipal = {
   __typename?: 'ClaimsPrincipal',
  /** Claims of the identity. */
  claims?: Maybe<Array<Maybe<Claim>>>,
};

export type CompleteDeviceLinkInput = {
  deviceTempId: Scalars['ID'],
  linkCode: Scalars['String'],
};

export type CompleteDeviceLinkPayload = {
   __typename?: 'CompleteDeviceLinkPayload',
  deviceCredentials: DeviceCredentials,
};

export type Component = {
   __typename?: 'Component',
  assets?: Maybe<Array<Maybe<Asset>>>,
  classNames?: Maybe<Scalars['String']>,
  componentType?: Maybe<ComponentTypeEnum>,
  configuration?: Maybe<Scalars['String']>,
  created: Scalars['DateTimeOffset'],
  dataSource?: Maybe<DataSource>,
  description?: Maybe<Scalars['String']>,
  descriptionTranslationKey?: Maybe<Scalars['String']>,
  iconName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  identifier?: Maybe<Scalars['String']>,
  isSystem: Scalars['Boolean'],
  isTemplate?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  nameTranslationKey?: Maybe<Scalars['String']>,
  /** Owning Organization */
  organization?: Maybe<Organization>,
  previewImage?: Maybe<Asset>,
  style?: Maybe<Scalars['String']>,
  tags?: Maybe<Array<Maybe<Tag>>>,
  updated: Scalars['DateTimeOffset'],
};

export type ComponentAssetInput = {
  component?: Maybe<UpdateComponentInput>,
  asset?: Maybe<UpdateAssetInput>,
};

export type ComponentQueryInput = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  ownedBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  isTemplate?: Maybe<Scalars['Boolean']>,
  isSystem?: Maybe<Scalars['Boolean']>,
  createdFrom?: Maybe<Scalars['DateTimeOffset']>,
  createdTo?: Maybe<Scalars['DateTimeOffset']>,
  updatedFrom?: Maybe<Scalars['DateTimeOffset']>,
  updatedTo?: Maybe<Scalars['DateTimeOffset']>,
};

export enum ComponentTypeEnum {
  /** Image component */
  Image = 'IMAGE',
  /** Background image component */
  BackgroundImage = 'BACKGROUND_IMAGE',
  /** Text table */
  TextTable = 'TEXT_TABLE',
  /** Smhi weather information */
  WeatherSmhi = 'WEATHER_SMHI',
  /** Web scraper for Parkgården Activities */
  ScraperParkgardenActivities = 'SCRAPER_PARKGARDEN_ACTIVITIES',
  /** Web scraper for Parkgården Lunch Menus */
  ScraperParkgardenLunch = 'SCRAPER_PARKGARDEN_LUNCH',
  /** Clock that shows current time based on timezone */
  TextClock = 'TEXT_CLOCK',
  /** Device Message */
  DeviceMessage = 'DEVICE_MESSAGE',
  /** Device Message Button */
  DeviceMessageButton = 'DEVICE_MESSAGE_BUTTON',
  /** Device Garbage Category Vote Button */
  DeviceGarbageCategoryVoteButton = 'DEVICE_GARBAGE_CATEGORY_VOTE_BUTTON',
  /** Device Event Action */
  DeviceEventAction = 'DEVICE_EVENT_ACTION',
  /** RSS News feed display */
  RssFeed = 'RSS_FEED',
  /** Calendar display */
  Calendar = 'CALENDAR',
  /** Text label display */
  TextLabel = 'TEXT_LABEL',
  /** Transit times (bus/train/etc) */
  TransitTimetable = 'TRANSIT_TIMETABLE',
  /** Name of day */
  TextNameOfDay = 'TEXT_NAME_OF_DAY',
  /** SnowFlake effect */
  FullscreenSnowflakeEffect = 'FULLSCREEN_SNOWFLAKE_EFFECT',
  /** Iframe */
  Iframe = 'IFRAME',
  /** Camera Barcode Reader */
  TextBarcodeReader = 'TEXT_BARCODE_READER',
  /** Keyboard Barcode Reader */
  TextKeyboardBarcodeReader = 'TEXT_KEYBOARD_BARCODE_READER',
  /** Image Carousel */
  ImageCarousel = 'IMAGE_CAROUSEL',
  /** Dexcom */
  Dexcom = 'DEXCOM'
}

export type Controller = {
   __typename?: 'Controller',
  id?: Maybe<Scalars['ID']>,
  isSystem: Scalars['Boolean'],
  name: Scalars['String'],
  /** Owning Organization */
  organization?: Maybe<Organization>,
  presentations?: Maybe<Array<Maybe<Presentation>>>,
};

export type ControllerQueryInput = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>,
  ownedBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  isSystem?: Maybe<Scalars['Boolean']>,
  createdFrom?: Maybe<Scalars['DateTimeOffset']>,
  createdTo?: Maybe<Scalars['DateTimeOffset']>,
  updatedFrom?: Maybe<Scalars['DateTimeOffset']>,
  updatedTo?: Maybe<Scalars['DateTimeOffset']>,
};

/** Country information */
export type Country = {
   __typename?: 'Country',
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Two letter country iso code */
  iso: Scalars['String'],
  /** Country name */
  name?: Maybe<Scalars['String']>,
};

/** Country information */
export type CountryInput = {
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Country name */
  name?: Maybe<Scalars['String']>,
  /** Two letter country iso code */
  iso: Scalars['String'],
};

export type CreateComponentInput = {
  organizationId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  componentType: Scalars['String'],
  configuration?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  transform?: Maybe<Scalars['String']>,
};

export type CreateComponentPayload = {
   __typename?: 'CreateComponentPayload',
  component: Component,
};

export type CreateDeviceControllerInput = {
  deviceId: Scalars['ID'],
};

export type CreateDeviceControllerPayload = {
   __typename?: 'CreateDeviceControllerPayload',
  controller: Controller,
};

export type CreateDeviceLinkTicketInput = {
  id: Scalars['ID'],
};

export type CreateLinkTicketPayload = {
   __typename?: 'CreateLinkTicketPayload',
  ticket: DeviceLinkTicket,
};

export type CreateOrganizationAssetsInput = {
  /** If of the organization that will own the assets */
  id?: Maybe<Scalars['ID']>,
  assets?: Maybe<Array<Maybe<AssetInput>>>,
};

export type CreateOrganizationAssetsPayload = {
   __typename?: 'CreateOrganizationAssetsPayload',
  assets?: Maybe<Array<Maybe<Asset>>>,
  organization?: Maybe<Organization>,
};

/** Represents the currently logged in user, his/her logged in identity and claims. */
export type CurrentUser = {
   __typename?: 'CurrentUser',
  /** Claims principal of the currently logged in identity */
  claimsPrincipal?: Maybe<ClaimsPrincipal>,
  /** Currently logged in identity. */
  identity?: Maybe<UserIdentity>,
  /** User profile of the currently logged in user. */
  user?: Maybe<User>,
};

export type DataSource = {
   __typename?: 'DataSource',
  assets?: Maybe<Array<Maybe<Asset>>>,
  configuration?: Maybe<Scalars['String']>,
  created: Scalars['DateTimeOffset'],
  dataSourceType?: Maybe<DataSourceTypeEnum>,
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  /** Owning Organization */
  organization?: Maybe<Organization>,
  updated: Scalars['DateTimeOffset'],
};

export type DataSourceInput = {
  organizationId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  dataSourceType: Scalars['String'],
  configuration?: Maybe<Scalars['String']>,
};

export type DataSourcePayload = {
   __typename?: 'DataSourcePayload',
  dataSource: DataSource,
};

export enum DataSourceTypeEnum {
  /** Json data source */
  JsonAssets = 'JSON_ASSETS',
  /** Resident registry */
  ResidentRegistry = 'RESIDENT_REGISTRY',
  /** Device message */
  DeviceMessage = 'DEVICE_MESSAGE',
  /** Device event */
  DeviceEvent = 'DEVICE_EVENT',
  /** iCal calendar feeds */
  CalendarIcal = 'CALENDAR_ICAL',
  /** RSS News feeds */
  RssFeeds = 'RSS_FEEDS',
  /** Transit stops */
  TransitStop = 'TRANSIT_STOP',
  /** Weather forecast */
  WeatherForecastSmhi = 'WEATHER_FORECAST_SMHI',
  /** Clock */
  Clock = 'CLOCK',
  /** Name of day */
  NameOfDay = 'NAME_OF_DAY',
  /** Dexcom */
  Dexcom = 'DEXCOM'
}





export type Device = {
   __typename?: 'Device',
  /** Geographical address of the device */
  address?: Maybe<Address>,
  /** Controller that manages the device schedules and behaviour */
  controller?: Maybe<Controller>,
  /** Display name of a device, example "My travel info display" */
  displayName: Scalars['String'],
  /** Feature flag representing what features a device supports */
  features?: Maybe<DeviceFeatures>,
  id?: Maybe<Scalars['ID']>,
  /** Name of this device in the IoT hub, automatically assigned on link */
  ioTDeviceId: Scalars['String'],
  /** Hardware model of the device, eg. Raspberry Pi3+, Samsung TV */
  model?: Maybe<Scalars['String']>,
  /** Organization that manages the device */
  organization?: Maybe<Organization>,
  /** Placement of the device, eg. Entrance, Living Room, Office, Meeting Room */
  placement?: Maybe<Scalars['String']>,
  /** Url to last received screenshot of the device */
  screenShotSasUrl?: Maybe<Scalars['String']>,
  /** Url to last received screenshot thumbnail of the device */
  screenShotThumbnailSasUrl?: Maybe<Scalars['String']>,
  /** The time zone id (.NET variant) of the time zone the device is */
  timeZoneId?: Maybe<Scalars['String']>,
  /** Height of the display area in pixels */
  viewportHeight: Scalars['Int'],
  /** Width of the display area in pixels */
  viewportWidth: Scalars['Int'],
};

/** Device context information */
export type DeviceContext = {
   __typename?: 'DeviceContext',
  /** Indicates the device is active or have been removed. */
  active: Scalars['Boolean'],
  controller?: Maybe<Controller>,
  deviceName: Scalars['String'],
  initialPresentation?: Maybe<Scalars['ID']>,
  /** Error describing the reason for an invalid license. */
  licenseErrorMessage?: Maybe<Scalars['String']>,
  organizationName: Scalars['String'],
  /** Iana TimeZone Id. See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timeZoneId?: Maybe<Scalars['String']>,
  /** Indicates if the owning Organization have a valid license. */
  validLicense: Scalars['Boolean'],
};

/** Device credentials information, can only be retrieved once during a linking sequence. */
export type DeviceCredentials = {
   __typename?: 'DeviceCredentials',
  deviceId?: Maybe<Scalars['ID']>,
  deviceIoTId: Scalars['String'],
  deviceIoTKey: Scalars['String'],
  ioTHost: Scalars['String'],
  linkComplete: Scalars['Boolean'],
  organizationId?: Maybe<Scalars['ID']>,
};

export enum DeviceFeatures {
  None = 'NONE',
  Display = 'DISPLAY',
  TouchInput = 'TOUCH_INPUT',
  MouseInput = 'MOUSE_INPUT',
  KeyboardInput = 'KEYBOARD_INPUT',
  BarcodeReaderInput = 'BARCODE_READER_INPUT',
  AudioOutput = 'AUDIO_OUTPUT'
}

/** Device link ticket */
export type DeviceLinkTicket = {
   __typename?: 'DeviceLinkTicket',
  code: Scalars['String'],
  deviceTempId?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  validUntil: Scalars['DateTimeOffset'],
};

export type DeviceMessage = ReloadCommand | SetLocalStorageValueCommand | SetPresentationCommand | UnlinkCommand | ToggleStatusbarCommand | ToggleDebugPanelCommand | RefreshContextCommand | RequestScreenShotCommand | DeviceMessageCommand;

export type DeviceMessageCommand = {
   __typename?: 'DeviceMessageCommand',
  message: Scalars['String'],
  name: Scalars['String'],
  target?: Maybe<Scalars['ID']>,
};

export type DeviceMessageEnvelope = {
   __typename?: 'DeviceMessageEnvelope',
  from?: Maybe<UnitAddress>,
  message?: Maybe<DeviceMessage>,
  to?: Maybe<UnitAddress>,
};

export type DevicePayload = {
   __typename?: 'DevicePayload',
  device: Device,
};

export type DeviceUpdateInput = {
  id?: Maybe<Scalars['ID']>,
  displayName: Scalars['String'],
  address?: Maybe<AddressInput>,
  model?: Maybe<Scalars['String']>,
  placement?: Maybe<Scalars['String']>,
  viewportWidth?: Maybe<Scalars['Int']>,
  viewportHeight?: Maybe<Scalars['Int']>,
  /** Iana TimeZone Id. See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timeZoneId?: Maybe<Scalars['String']>,
  features?: Maybe<DeviceFeatures>,
};

export type HomeScreenInformation = {
   __typename?: 'HomeScreenInformation',
  componentCount: Scalars['Int'],
  dataSourceCount: Scalars['Int'],
  deviceCount: Scalars['Int'],
  hasInitializedComponent: Scalars['Boolean'],
  hasInitializedDevice: Scalars['Boolean'],
  hasInitializedOrganization: Scalars['Boolean'],
  lastActivity: Array<Maybe<LastActivityItem>>,
  notifications?: Maybe<Array<Maybe<Notification>>>,
  organizationCount: Scalars['Int'],
};

export enum HorizontalAlign {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type LastActivityItem = {
   __typename?: 'LastActivityItem',
  itemName: Scalars['String'],
  timestamp: Scalars['DateTimeOffset'],
  user?: Maybe<User>,
};

export type LinkDeviceInput = {
  organizationId: Scalars['ID'],
  linkCode: Scalars['String'],
  displayName: Scalars['String'],
};

export type LinkDevicePayload = {
   __typename?: 'LinkDevicePayload',
  device: Device,
  result?: Maybe<Scalars['Boolean']>,
};

/** A WGS 84 longitude/latitude coordinate */
export type Location = {
   __typename?: 'Location',
  /** Latitude */
  latitude: Scalars['Float'],
  /** Longitude */
  longitude: Scalars['Float'],
};

/** A WGS 84 longitude/latitude coordinate */
export type LocationInput = {
  /** Longitude */
  longitude: Scalars['Float'],
  /** Latitude */
  latitude: Scalars['Float'],
};

export type LunchDay = {
   __typename?: 'LunchDay',
  alternative?: Maybe<Scalars['String']>,
  dessert?: Maybe<Scalars['String']>,
  lunch?: Maybe<Scalars['String']>,
  nameOfDay: Scalars['String'],
};

export type LunchWeek = {
   __typename?: 'LunchWeek',
  days: Array<Maybe<LunchDay>>,
  number: Scalars['Int'],
  title: Scalars['String'],
};


export type Mutation = {
   __typename?: 'Mutation',
  /** Associates a payment method with an organization. */
  addOrganizationPaymentMethod?: Maybe<AddOrganizationPaymentMethodPayload>,
  /** Assign an asset to a component. Requires ownership permissions. */
  assignComponentAsset?: Maybe<AssignComponentAssetPayload>,
  /** Assign an data source to a component. Requires ownership permissions. */
  assignComponentDataSource?: Maybe<AssignComponentDataSourcePayload>,
  /** Assign an asset to a data source. Requires ownership permissions. */
  assignDataSourceAsset?: Maybe<AssignDataSourceAssetPayload>,
  /** Update address value. Requires ownership permissions. */
  assignDeviceAddress?: Maybe<AssignDeviceAddressPayload>,
  /** Update controller value. Requires ownership permissions. */
  assignDeviceController?: Maybe<DevicePayload>,
  /** Assign an page to a device. Requires ownership permissions. */
  assignDevicePage?: Maybe<DevicePayload>,
  /** Assign a component to a page. Requires ownership permissions. */
  assignPageComponent?: Maybe<AssignPageComponentPayload>,
  /** Cancel a subscription for the organization */
  cancelOrganizationSubscription?: Maybe<CancelOrganizationSubscriptionPayload>,
  /** 
 * Get device credentials after linking is done. Can only be called once after
   * device have been linked, ticket will invalidate.
 **/
  completeDeviceLink?: Maybe<CompleteDeviceLinkPayload>,
  /** Create a new address. */
  createAddress?: Maybe<AddressPayload>,
  /** Create a new page component. */
  createComponent?: Maybe<CreateComponentPayload>,
  /** Creates an empty controller */
  createController?: Maybe<CreateDeviceControllerPayload>,
  /** Create a new data source. */
  createDataSource?: Maybe<DataSourcePayload>,
  /** Get a link ticket for a device, called by a device. Must be followed by a linkDevice call in order to link a device. */
  createDeviceLinkTicket?: Maybe<CreateLinkTicketPayload>,
  /** Create a new organization. */
  createOrganization?: Maybe<OrganizationPayload>,
  /** Creates assets for the organization and returns SAS tokens with short term upload rights. Requires ownership permissions. */
  createOrganizationAssets?: Maybe<CreateOrganizationAssetsPayload>,
  /** Link a device to an organization. Requires ownership permissions for the Organization. */
  linkDevice?: Maybe<LinkDevicePayload>,
  /** Notify devices and other users of an asset that an asset have been changed. */
  notifyAssetChanged?: Maybe<Scalars['Boolean']>,
  /** Remove an asset. Requires ownership permissions. */
  removeAsset?: Maybe<RemoveAssetPayload>,
  /** Remove a page component. Requires ownership permissions. */
  removeComponent?: Maybe<RemoveComponentPayload>,
  /** Remove a data source. Requires ownership permissions. */
  removeDataSource?: Maybe<RemoveDataSourcePayload>,
  /** Unlinks a device from it´s organization and detaches it from iot services. */
  removeDevice?: Maybe<RemoveDevicePayload>,
  /** Removes an organization and all it´s devices. Requires ownership permissions. */
  removeOrganization?: Maybe<OrganizationPayload>,
  /** Removes a payment method from an organization. */
  removeOrganizationPaymentMethod?: Maybe<RemoveOrganizationPaymentMethodPayload>,
  /** Send a command to a target. Requires ownership permissions for the target. */
  sendCommand?: Maybe<SendCommandPayload>,
  /** Creates an empty controller, presentation and page for a newly linked device */
  setupNewDevice?: Maybe<DevicePayload>,
  /** Unassign an asset from a component. Requires ownership permissions. */
  unassignComponentAsset?: Maybe<UnassignComponentAssetPayload>,
  /** Unassign a data source from a component. Requires ownership permissions. */
  unassignComponentDataSource?: Maybe<UnassignComponentDataSourcePayload>,
  /** Unassign an asset from a data source. Requires ownership permissions. */
  unassignDataSourceAsset?: Maybe<UnassignDataSourceAssetPayload>,
  /** Remove a component from a page. Requires ownership permissions. */
  unassignPageComponent?: Maybe<UnassignPageComponentPayload>,
  /** Update the value of an existing address. Requires ownership permissions. */
  updateAddress?: Maybe<AddressPayload>,
  /** Update an asset. Requires ownership permissions. */
  updateAsset?: Maybe<UpdateAssetPayload>,
  /** Update a page component. Requires ownership permissions. */
  updateComponent?: Maybe<UpdateComponentPayload>,
  /** Update a data source. Requires ownership permissions. */
  updateDataSource?: Maybe<UpdateDataSourcePayload>,
  /** Update device values. Requires ownership permissions. */
  updateDevice?: Maybe<DevicePayload>,
  /** Update organization values. Requires ownership permissions. */
  updateOrganization?: Maybe<OrganizationPayload>,
  /** Selects a subscription for the organization, requires that a payment method exists associated with the organization. */
  updateOrganizationSubscription?: Maybe<UpdateOrganizationSubscriptionPayload>,
  /** Update a page. Requires ownership permissions. */
  updatePage?: Maybe<UpdatePagePayload>,
};


export type MutationAddOrganizationPaymentMethodArgs = {
  input: AddOrganizationPaymentMethodInput
};


export type MutationAssignComponentAssetArgs = {
  input: AssignComponentAssetInput
};


export type MutationAssignComponentDataSourceArgs = {
  input: AssignComponentDataSourceInput
};


export type MutationAssignDataSourceAssetArgs = {
  input: AssignDataSourceAssetInput
};


export type MutationAssignDeviceAddressArgs = {
  input: AssignDeviceAddressInput
};


export type MutationAssignDeviceControllerArgs = {
  input: AssignDeviceControllerInput
};


export type MutationAssignDevicePageArgs = {
  input: AssignDevicePageInput
};


export type MutationAssignPageComponentArgs = {
  input: AssignPageComponentInput
};


export type MutationCancelOrganizationSubscriptionArgs = {
  input: CancelOrganizationSubscriptionInput
};


export type MutationCompleteDeviceLinkArgs = {
  input: CompleteDeviceLinkInput
};


export type MutationCreateAddressArgs = {
  input: AddressInput
};


export type MutationCreateComponentArgs = {
  input: CreateComponentInput
};


export type MutationCreateControllerArgs = {
  input: CreateDeviceControllerInput
};


export type MutationCreateDataSourceArgs = {
  input: DataSourceInput
};


export type MutationCreateDeviceLinkTicketArgs = {
  input: CreateDeviceLinkTicketInput
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationInput
};


export type MutationCreateOrganizationAssetsArgs = {
  input: CreateOrganizationAssetsInput
};


export type MutationLinkDeviceArgs = {
  input: LinkDeviceInput
};


export type MutationNotifyAssetChangedArgs = {
  input: Scalars['ID']
};


export type MutationRemoveAssetArgs = {
  input: Scalars['ID']
};


export type MutationRemoveComponentArgs = {
  input: Scalars['ID']
};


export type MutationRemoveDataSourceArgs = {
  input: Scalars['ID']
};


export type MutationRemoveDeviceArgs = {
  input: RemoveDeviceInput
};


export type MutationRemoveOrganizationArgs = {
  input: Scalars['ID']
};


export type MutationRemoveOrganizationPaymentMethodArgs = {
  input: RemoveOrganizationPaymentMethodInput
};


export type MutationSendCommandArgs = {
  input: SendCommandInput
};


export type MutationSetupNewDeviceArgs = {
  input: SetupNewDeviceInput
};


export type MutationUnassignComponentAssetArgs = {
  input: UnassignComponentAssetInput
};


export type MutationUnassignComponentDataSourceArgs = {
  input: UnassignComponentDataSourceInput
};


export type MutationUnassignDataSourceAssetArgs = {
  input: UnassignDataSourceAssetInput
};


export type MutationUnassignPageComponentArgs = {
  input: UnassignPageComponentInput
};


export type MutationUpdateAddressArgs = {
  input: AddressInput
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput
};


export type MutationUpdateComponentArgs = {
  input: UpdateComponentInput
};


export type MutationUpdateDataSourceArgs = {
  input: UpdateDataSourceInput
};


export type MutationUpdateDeviceArgs = {
  input: DeviceUpdateInput
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationUpdateInput
};


export type MutationUpdateOrganizationSubscriptionArgs = {
  input: UpdateOrganizationSubscriptionInput
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput
};

/** Nassjo kommun web scrapers. */
export type NassjoKommunScraperQueries = {
   __typename?: 'NassjoKommunScraperQueries',
  monthActivities: Array<Maybe<ScheduledActivity>>,
  weekLunchMenus: Array<Maybe<LunchWeek>>,
};

export type Notification = {
   __typename?: 'Notification',
  notificationType?: Maybe<NotificationType>,
  subject: Scalars['String'],
  subjectId?: Maybe<Scalars['ID']>,
};

export enum NotificationType {
  OrganizationMissingValidSubscription = 'ORGANIZATION_MISSING_VALID_SUBSCRIPTION',
  OrganizationDeviceCountExceeded = 'ORGANIZATION_DEVICE_COUNT_EXCEEDED'
}

/** Owner entity for devices, users, security and billing information */
export type Organization = {
   __typename?: 'Organization',
  /** Billing address of the organization */
  billingAddress?: Maybe<Address>,
  billingEmail?: Maybe<Scalars['String']>,
  contactEmail?: Maybe<Scalars['String']>,
  /** Devices linked to this organization */
  devices?: Maybe<Array<Maybe<Device>>>,
  /** Indicates if the organization have a valid subscription covering the number of devices registered */
  hasValidSubscription?: Maybe<Scalars['Boolean']>,
  /** Id of the organization */
  id?: Maybe<Scalars['ID']>,
  isSystem: Scalars['Boolean'],
  /** Logotype of the organization */
  logotype?: Maybe<Asset>,
  /** Name of the organization */
  name: Scalars['String'],
  /** Office address of the organization */
  officeAddress?: Maybe<Address>,
  organizationNumber?: Maybe<Scalars['String']>,
  /** Get current organization subscription */
  organizationSubscription?: Maybe<OrganizationSubscription>,
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>,
  phoneNumber?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  /** Users with access to this organization */
  users?: Maybe<Array<Maybe<User>>>,
  vatNumber?: Maybe<Scalars['String']>,
};

/** Owner entity for devices, users, security and billing information */
export type OrganizationInput = {
  /** Name of the organization */
  name: Scalars['String'],
  /** Billing address of the organization */
  billingAddress?: Maybe<AddressInput>,
  /** Office address of the organization */
  officeAddress?: Maybe<AddressInput>,
};

export type OrganizationPayload = {
   __typename?: 'OrganizationPayload',
  organization: Organization,
};

export type OrganizationSubscription = {
   __typename?: 'OrganizationSubscription',
  externalStatus: Scalars['String'],
  quantity: Scalars['Int'],
  subscriptionPlan?: Maybe<SubscriptionPlan>,
  validTo: Scalars['DateTimeOffset'],
};

/** Owner entity for devices, users, security and billing information */
export type OrganizationUpdateInput = {
  id?: Maybe<Scalars['ID']>,
  /** Name of the organization */
  name: Scalars['String'],
  vatNumber?: Maybe<Scalars['String']>,
  organizationNumber?: Maybe<Scalars['String']>,
  billingEmail?: Maybe<Scalars['String']>,
  contactEmail?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  /** Billing address of the organization */
  billingAddress?: Maybe<AddressInput>,
  /** Office address of the organization */
  officeAddress?: Maybe<AddressInput>,
  /** Logotype of the organization */
  logotype?: Maybe<AssetInput>,
};

export type Page = {
   __typename?: 'Page',
  components?: Maybe<Array<Maybe<PageComponent>>>,
  created: Scalars['DateTimeOffset'],
  id?: Maybe<Scalars['ID']>,
  isSystem: Scalars['Boolean'],
  name: Scalars['String'],
  /** Owning Organization */
  organization?: Maybe<Organization>,
  updated: Scalars['DateTimeOffset'],
};

export type PageComponent = {
   __typename?: 'PageComponent',
  component?: Maybe<Component>,
  height?: Maybe<Scalars['String']>,
  horizontalAlign?: Maybe<HorizontalAlign>,
  page?: Maybe<Page>,
  transform?: Maybe<Scalars['String']>,
  transformOrigin?: Maybe<Scalars['String']>,
  verticalAlign?: Maybe<VerticalAlign>,
  width?: Maybe<Scalars['String']>,
  zIndex?: Maybe<Scalars['String']>,
};

export type PageComponentInput = {
  pageId?: Maybe<Scalars['ID']>,
  component?: Maybe<UpdateComponentInput>,
  active?: Maybe<Scalars['Boolean']>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  zIndex?: Maybe<Scalars['String']>,
  transform?: Maybe<Scalars['String']>,
  transformOrigin?: Maybe<Scalars['String']>,
  horizontalAlign?: Maybe<HorizontalAlign>,
  verticalAlign?: Maybe<VerticalAlign>,
};

export type PageQueryInput = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>,
  ownedBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  isSystem?: Maybe<Scalars['Boolean']>,
  createdFrom?: Maybe<Scalars['DateTimeOffset']>,
  createdTo?: Maybe<Scalars['DateTimeOffset']>,
  updatedFrom?: Maybe<Scalars['DateTimeOffset']>,
  updatedTo?: Maybe<Scalars['DateTimeOffset']>,
};

export type PaymentMethod = {
   __typename?: 'PaymentMethod',
  created: Scalars['DateTimeOffset'],
  id?: Maybe<Scalars['ID']>,
  isDefault: Scalars['Boolean'],
  name: Scalars['String'],
  updated: Scalars['DateTimeOffset'],
};

export type Presentation = {
   __typename?: 'Presentation',
  created: Scalars['DateTimeOffset'],
  id?: Maybe<Scalars['ID']>,
  isSystem: Scalars['Boolean'],
  name: Scalars['String'],
  /** Owning Organization */
  organization?: Maybe<Organization>,
  pages?: Maybe<Array<Maybe<Page>>>,
  updated: Scalars['DateTimeOffset'],
};

export type PresentationQueryInput = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>,
  ownedBy?: Maybe<Array<Maybe<Scalars['String']>>>,
  isSystem?: Maybe<Scalars['Boolean']>,
  createdFrom?: Maybe<Scalars['DateTimeOffset']>,
  createdTo?: Maybe<Scalars['DateTimeOffset']>,
  updatedFrom?: Maybe<Scalars['DateTimeOffset']>,
  updatedTo?: Maybe<Scalars['DateTimeOffset']>,
};

export type Query = {
   __typename?: 'Query',
  /** Get address by id. */
  address?: Maybe<Address>,
  /** Debug information regarding the current app instance. */
  application: ApplicationInformation,
  /** Get asset by id. */
  asset?: Maybe<Asset>,
  /** All assets the current users have access to */
  assets: Array<Maybe<Asset>>,
  /** Query audit entries, requires read:audit permissions. */
  auditEntries: Array<Maybe<AuditEntry>>,
  /** Get audit entry by id, requires read:audit permissions. */
  auditEntry?: Maybe<AuditEntry>,
  /** All components current user have access to */
  components: Array<Maybe<Component>>,
  /** Get controller by id. */
  controller?: Maybe<Controller>,
  /** Query controllers accessible for the user */
  controllers: Array<Maybe<Controller>>,
  /** Get the currently logged in user identity. */
  currentUser: CurrentUser,
  /** Get data source by id. */
  dataSource?: Maybe<DataSource>,
  /** All active data sources the current user have access to */
  dataSources: Array<Maybe<DataSource>>,
  /** Get device by id. */
  device?: Maybe<Device>,
  /** Get device context data by id. */
  deviceContext: DeviceContext,
  /** All devices the current users have access to */
  devices: Array<Maybe<Device>>,
  /** Get home screen information. */
  home?: Maybe<HomeScreenInformation>,
  /** Get organization by id. */
  organization?: Maybe<Organization>,
  /** All organizations that the currently logged in user have access to. */
  organizations: Array<Maybe<Organization>>,
  /** Get page by id. */
  page?: Maybe<Page>,
  /** Query pages accessible for the user */
  pages: Array<Maybe<Page>>,
  /** Get presentation by id. */
  presentation?: Maybe<Presentation>,
  /** Query presentations accessible for the user */
  presentations: Array<Maybe<Presentation>>,
  /** Web scraper contents */
  scrapers: ScraperQueries,
  /** All available subscription plans */
  subscriptionPlans: Array<Maybe<SubscriptionPlan>>,
  /** All tags current users have access to */
  tags: Array<Maybe<Tag>>,
  /** Get user by id. */
  user?: Maybe<User>,
  /** All users that the currently logged in user have access to. */
  users: Array<Maybe<User>>,
};


export type QueryAddressArgs = {
  id: Scalars['ID']
};


export type QueryAssetArgs = {
  id: Scalars['ID']
};


export type QueryAssetsArgs = {
  input?: Maybe<AssetQueryInput>
};


export type QueryAuditEntriesArgs = {
  input?: Maybe<AuditEntryQueryInput>
};


export type QueryAuditEntryArgs = {
  id: Scalars['ID']
};


export type QueryComponentsArgs = {
  input?: Maybe<ComponentQueryInput>
};


export type QueryControllerArgs = {
  id: Scalars['ID']
};


export type QueryControllersArgs = {
  input?: Maybe<ControllerQueryInput>
};


export type QueryDataSourceArgs = {
  id: Scalars['ID']
};


export type QueryDeviceArgs = {
  id: Scalars['ID']
};


export type QueryDeviceContextArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']
};


export type QueryPageArgs = {
  id: Scalars['ID']
};


export type QueryPagesArgs = {
  input?: Maybe<PageQueryInput>
};


export type QueryPresentationArgs = {
  id: Scalars['ID']
};


export type QueryPresentationsArgs = {
  input?: Maybe<PresentationQueryInput>
};


export type QueryTagsArgs = {
  input?: Maybe<TagQueryInput>
};


export type QueryUserArgs = {
  id: Scalars['ID']
};

export type RefreshContextCommand = {
   __typename?: 'RefreshContextCommand',
  name: Scalars['String'],
};

export type ReloadCommand = {
   __typename?: 'ReloadCommand',
  name: Scalars['String'],
};

export type RemoveAssetPayload = {
   __typename?: 'RemoveAssetPayload',
  asset: Asset,
  result?: Maybe<Scalars['Boolean']>,
};

export type RemoveComponentPayload = {
   __typename?: 'RemoveComponentPayload',
  component?: Maybe<Component>,
  result?: Maybe<Scalars['Boolean']>,
};

export type RemoveDataSourcePayload = {
   __typename?: 'RemoveDataSourcePayload',
  dataSource: DataSource,
  result?: Maybe<Scalars['Boolean']>,
};

export type RemoveDeviceInput = {
  deviceId: Scalars['ID'],
};

export type RemoveDevicePayload = {
   __typename?: 'RemoveDevicePayload',
  device: Device,
  result?: Maybe<Scalars['Boolean']>,
};

export type RemoveOrganizationPaymentMethodInput = {
  organizationId?: Maybe<Scalars['ID']>,
  paymentMethodId?: Maybe<Scalars['ID']>,
};

export type RemoveOrganizationPaymentMethodPayload = {
   __typename?: 'RemoveOrganizationPaymentMethodPayload',
  organization?: Maybe<Organization>,
  result?: Maybe<Scalars['Boolean']>,
};

export type RequestScreenShotCommand = {
   __typename?: 'RequestScreenShotCommand',
  imageSasUrl: Scalars['String'],
  name: Scalars['String'],
  thumbnailSasUrl: Scalars['String'],
};

export type ScheduledActivity = {
   __typename?: 'ScheduledActivity',
  content?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['DateTime']>,
  startTime?: Maybe<Scalars['DateTime']>,
  title: Scalars['String'],
};

/** Scraper queries */
export type ScraperQueries = {
   __typename?: 'ScraperQueries',
  /** Nassjo kommun web scrapers */
  nassjokommun: NassjoKommunScraperQueries,
};


export type SendCommandInput = {
  targetId: Scalars['ID'],
  commandName: Scalars['String'],
  data: Scalars['String'],
};

export type SendCommandPayload = {
   __typename?: 'SendCommandPayload',
  success: Scalars['Boolean'],
};

export type SetLocalStorageValueCommand = {
   __typename?: 'SetLocalStorageValueCommand',
  name: Scalars['String'],
  storageKey: Scalars['String'],
  storageValue: Scalars['String'],
};

export type SetPresentationCommand = {
   __typename?: 'SetPresentationCommand',
  name: Scalars['String'],
  presentation: Scalars['String'],
};

export type SetupNewDeviceInput = {
  deviceId: Scalars['ID'],
};

/** Subdivision (area/state) information including geographical location */
export type Subdivision = {
   __typename?: 'Subdivision',
  /** Country */
  country: Country,
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Name of subdivision */
  name: Scalars['String'],
};

/** Subdivision (area/state) information including geographical location */
export type SubdivisionInput = {
  /** Id */
  id?: Maybe<Scalars['ID']>,
  /** Name of subdivision */
  name: Scalars['String'],
  /** Country */
  country: CountryInput,
};

export type SubscriptionPlan = {
   __typename?: 'SubscriptionPlan',
  descriptionTranslationKey: Scalars['String'],
  externalId: Scalars['String'],
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  nameTranslationKey: Scalars['String'],
};

export type Tag = {
   __typename?: 'Tag',
  created: Scalars['DateTimeOffset'],
  description?: Maybe<Scalars['String']>,
  descriptionTranslationKey?: Maybe<Scalars['String']>,
  iconName: Scalars['String'],
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  nameTranslationKey?: Maybe<Scalars['String']>,
  parent?: Maybe<Tag>,
  parentId?: Maybe<Scalars['ID']>,
  updated: Scalars['DateTimeOffset'],
};

export type TagQueryInput = {
  query?: Maybe<Scalars['String']>,
  tagTypes?: Maybe<Array<Maybe<TagType>>>,
};

export enum TagType {
  Generic = 'GENERIC',
  ComponentCategory = 'COMPONENT_CATEGORY',
  GarbageProductSortCategory = 'GARBAGE_PRODUCT_SORT_CATEGORY'
}

export type ToggleDebugPanelCommand = {
   __typename?: 'ToggleDebugPanelCommand',
  name: Scalars['String'],
  visible: Scalars['Boolean'],
};

export type ToggleStatusbarCommand = {
   __typename?: 'ToggleStatusbarCommand',
  name: Scalars['String'],
  visible: Scalars['Boolean'],
};

export type UnassignComponentAssetInput = {
  componentId: Scalars['ID'],
  assetId: Scalars['ID'],
};

export type UnassignComponentAssetPayload = {
   __typename?: 'UnassignComponentAssetPayload',
  asset?: Maybe<Asset>,
  component?: Maybe<Component>,
  result?: Maybe<Scalars['Boolean']>,
};

export type UnassignComponentDataSourceInput = {
  componentId: Scalars['ID'],
};

export type UnassignComponentDataSourcePayload = {
   __typename?: 'UnassignComponentDataSourcePayload',
  component?: Maybe<Component>,
  dataSource?: Maybe<DataSource>,
  result?: Maybe<Scalars['Boolean']>,
};

export type UnassignDataSourceAssetInput = {
  dataSourceId: Scalars['ID'],
  assetId: Scalars['ID'],
};

export type UnassignDataSourceAssetPayload = {
   __typename?: 'UnassignDataSourceAssetPayload',
  asset?: Maybe<Asset>,
  dataSource?: Maybe<DataSource>,
  result?: Maybe<Scalars['Boolean']>,
};

export type UnassignPageComponentInput = {
  componentId: Scalars['ID'],
  pageId: Scalars['ID'],
};

export type UnassignPageComponentPayload = {
   __typename?: 'UnassignPageComponentPayload',
  component: Component,
  page: Page,
  result?: Maybe<Scalars['Boolean']>,
};

export type UnitAddress = {
   __typename?: 'UnitAddress',
  componentId?: Maybe<Scalars['ID']>,
  deviceId?: Maybe<Scalars['ID']>,
  iotId: Scalars['String'],
};

export type UnlinkCommand = {
   __typename?: 'UnlinkCommand',
  name: Scalars['String'],
};

export type UpdateAssetInput = {
  id?: Maybe<Scalars['ID']>,
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
};

export type UpdateAssetPayload = {
   __typename?: 'UpdateAssetPayload',
  asset?: Maybe<Asset>,
  result: Scalars['Boolean'],
};

export type UpdateComponentInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  componentType: Scalars['String'],
  configuration?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  isTemplate?: Maybe<Scalars['Boolean']>,
  iconName?: Maybe<Scalars['String']>,
  classNames?: Maybe<Scalars['String']>,
  identifier?: Maybe<Scalars['String']>,
  previewImage?: Maybe<UpdateAssetInput>,
  dataSource?: Maybe<UpdateDataSourceInput>,
  assets?: Maybe<Array<Maybe<ComponentAssetInput>>>,
};

export type UpdateComponentPayload = {
   __typename?: 'UpdateComponentPayload',
  component?: Maybe<Component>,
  result: Scalars['Boolean'],
};

export type UpdateDataSourceInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  configuration: Scalars['String'],
};

export type UpdateDataSourcePayload = {
   __typename?: 'UpdateDataSourcePayload',
  dataSource: DataSource,
  result: Scalars['Boolean'],
};

export type UpdateOrganizationSubscriptionInput = {
  organizationId?: Maybe<Scalars['ID']>,
  subscriptionExternalId: Scalars['String'],
  quantity: Scalars['Int'],
};

export type UpdateOrganizationSubscriptionPayload = {
   __typename?: 'UpdateOrganizationSubscriptionPayload',
  clientSecret?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  status?: Maybe<Scalars['String']>,
};

export type UpdatePageInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  components?: Maybe<Array<Maybe<PageComponentInput>>>,
};

export type UpdatePagePayload = {
   __typename?: 'UpdatePagePayload',
  page?: Maybe<Page>,
  result: Scalars['Boolean'],
};

/** A Rduce Complexity AB user with access rights to Pixon. */
export type User = {
   __typename?: 'User',
  email?: Maybe<Scalars['String']>,
  emailVerified?: Maybe<Scalars['Boolean']>,
  enabled?: Maybe<Scalars['Boolean']>,
  externalCreatedAt?: Maybe<Scalars['DateTimeOffset']>,
  externalUserId?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  /** Id user (note: different from the identity id, a user may have multiple identities) */
  id?: Maybe<Scalars['ID']>,
  lastName?: Maybe<Scalars['String']>,
  locale?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  /** Main username of the user, note that a user may have multiple usernames based on the number of identities he/she have. */
  username?: Maybe<Scalars['String']>,
};

/** Represents the currently logged in user, his/her logged in identity and claims. */
export type UserIdentity = {
   __typename?: 'UserIdentity',
  /** Id of identity (note: different from user id since a user may have multiple identities) */
  id?: Maybe<Scalars['ID']>,
  /** Name of identity provider. */
  identityProvider?: Maybe<Scalars['String']>,
  /** Id of identity given from the provider. */
  providerUserId?: Maybe<Scalars['String']>,
};

export enum VerticalAlign {
  Top = 'TOP',
  Center = 'CENTER',
  Bottom = 'BOTTOM'
}

export type CompleteDeviceLinkMutationVariables = {
  input: CompleteDeviceLinkInput
};


export type CompleteDeviceLinkMutation = (
  { __typename?: 'Mutation' }
  & { completeDeviceLink: Maybe<(
    { __typename?: 'CompleteDeviceLinkPayload' }
    & { deviceCredentials: (
      { __typename?: 'DeviceCredentials' }
      & Pick<DeviceCredentials, 'deviceId' | 'deviceIoTId' | 'deviceIoTKey' | 'linkComplete' | 'ioTHost' | 'organizationId'>
    ) }
  )> }
);

export type CreateDeviceLinkTicketMutationVariables = {
  input: CreateDeviceLinkTicketInput
};


export type CreateDeviceLinkTicketMutation = (
  { __typename?: 'Mutation' }
  & { createDeviceLinkTicket: Maybe<(
    { __typename?: 'CreateLinkTicketPayload' }
    & { ticket: (
      { __typename?: 'DeviceLinkTicket' }
      & Pick<DeviceLinkTicket, 'code' | 'id' | 'deviceTempId' | 'validUntil'>
    ) }
  )> }
);

export type GetDeviceContextQueryVariables = {
  id: Scalars['ID']
};


export type GetDeviceContextQuery = (
  { __typename?: 'Query' }
  & { deviceContext: (
    { __typename?: 'DeviceContext' }
    & Pick<DeviceContext, 'active' | 'validLicense' | 'licenseErrorMessage' | 'organizationName' | 'initialPresentation'>
    & { controller: Maybe<(
      { __typename?: 'Controller' }
      & Pick<Controller, 'id'>
      & { presentations: Maybe<Array<Maybe<(
        { __typename?: 'Presentation' }
        & Pick<Presentation, 'id' | 'name'>
        & { pages: Maybe<Array<Maybe<(
          { __typename?: 'Page' }
          & Pick<Page, 'id' | 'name'>
          & { components: Maybe<Array<Maybe<(
            { __typename?: 'PageComponent' }
            & Pick<PageComponent, 'transform' | 'transformOrigin' | 'height' | 'width' | 'horizontalAlign' | 'verticalAlign' | 'zIndex'>
            & { page: Maybe<(
              { __typename?: 'Page' }
              & Pick<Page, 'id'>
            )>, component: Maybe<(
              { __typename?: 'Component' }
              & Pick<Component, 'id' | 'name' | 'componentType' | 'configuration' | 'style' | 'classNames' | 'identifier'>
              & { assets: Maybe<Array<Maybe<(
                { __typename?: 'Asset' }
                & Pick<Asset, 'id' | 'assetType' | 'mimeType' | 'lastShortTermReadToken'>
              )>>>, dataSource: Maybe<(
                { __typename?: 'DataSource' }
                & Pick<DataSource, 'id' | 'name' | 'dataSourceType' | 'configuration'>
                & { assets: Maybe<Array<Maybe<(
                  { __typename?: 'Asset' }
                  & Pick<Asset, 'id' | 'assetType' | 'mimeType' | 'lastShortTermReadToken'>
                )>>> }
              )> }
            )> }
          )>>> }
        )>>> }
      )>>> }
    )> }
  ) }
);

export type ParkgardenMonthActivitiesQueryVariables = {};


export type ParkgardenMonthActivitiesQuery = (
  { __typename?: 'Query' }
  & { scrapers: (
    { __typename?: 'ScraperQueries' }
    & { nassjokommun: (
      { __typename?: 'NassjoKommunScraperQueries' }
      & { monthActivities: Array<Maybe<(
        { __typename?: 'ScheduledActivity' }
        & Pick<ScheduledActivity, 'title' | 'startTime' | 'endTime' | 'content'>
      )>> }
    ) }
  ) }
);

export type ParkgardenWeekLunchQueryVariables = {};


export type ParkgardenWeekLunchQuery = (
  { __typename?: 'Query' }
  & { scrapers: (
    { __typename?: 'ScraperQueries' }
    & { nassjokommun: (
      { __typename?: 'NassjoKommunScraperQueries' }
      & { weekLunchMenus: Array<Maybe<(
        { __typename?: 'LunchWeek' }
        & Pick<LunchWeek, 'title' | 'number'>
        & { days: Array<Maybe<(
          { __typename?: 'LunchDay' }
          & Pick<LunchDay, 'nameOfDay' | 'lunch' | 'alternative' | 'dessert'>
        )>> }
      )>> }
    ) }
  ) }
);


export const CompleteDeviceLinkDocument = gql`
    mutation CompleteDeviceLink($input: CompleteDeviceLinkInput!) {
  completeDeviceLink(input: $input) {
    deviceCredentials {
      deviceId
      deviceIoTId
      deviceIoTKey
      linkComplete
      ioTHost
      organizationId
    }
  }
}
    `;
export type CompleteDeviceLinkMutationFn = ApolloReactCommon.MutationFunction<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>;
export type CompleteDeviceLinkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>, 'mutation'>;

    export const CompleteDeviceLinkComponent = (props: CompleteDeviceLinkComponentProps) => (
      <ApolloReactComponents.Mutation<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables> mutation={CompleteDeviceLinkDocument} {...props} />
    );
    
export type CompleteDeviceLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables> & TChildProps;
export function withCompleteDeviceLink<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompleteDeviceLinkMutation,
  CompleteDeviceLinkMutationVariables,
  CompleteDeviceLinkProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables, CompleteDeviceLinkProps<TChildProps>>(CompleteDeviceLinkDocument, {
      alias: 'completeDeviceLink',
      ...operationOptions
    });
};

/**
 * __useCompleteDeviceLinkMutation__
 *
 * To run a mutation, you first call `useCompleteDeviceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDeviceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDeviceLinkMutation, { data, loading, error }] = useCompleteDeviceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteDeviceLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>(CompleteDeviceLinkDocument, baseOptions);
      }
export type CompleteDeviceLinkMutationHookResult = ReturnType<typeof useCompleteDeviceLinkMutation>;
export type CompleteDeviceLinkMutationResult = ApolloReactCommon.MutationResult<CompleteDeviceLinkMutation>;
export type CompleteDeviceLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>;
export const CreateDeviceLinkTicketDocument = gql`
    mutation CreateDeviceLinkTicket($input: CreateDeviceLinkTicketInput!) {
  createDeviceLinkTicket(input: $input) {
    ticket {
      code
      id
      deviceTempId
      validUntil
    }
  }
}
    `;
export type CreateDeviceLinkTicketMutationFn = ApolloReactCommon.MutationFunction<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>;
export type CreateDeviceLinkTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>, 'mutation'>;

    export const CreateDeviceLinkTicketComponent = (props: CreateDeviceLinkTicketComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables> mutation={CreateDeviceLinkTicketDocument} {...props} />
    );
    
export type CreateDeviceLinkTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables> & TChildProps;
export function withCreateDeviceLinkTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDeviceLinkTicketMutation,
  CreateDeviceLinkTicketMutationVariables,
  CreateDeviceLinkTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables, CreateDeviceLinkTicketProps<TChildProps>>(CreateDeviceLinkTicketDocument, {
      alias: 'createDeviceLinkTicket',
      ...operationOptions
    });
};

/**
 * __useCreateDeviceLinkTicketMutation__
 *
 * To run a mutation, you first call `useCreateDeviceLinkTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceLinkTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceLinkTicketMutation, { data, loading, error }] = useCreateDeviceLinkTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceLinkTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>(CreateDeviceLinkTicketDocument, baseOptions);
      }
export type CreateDeviceLinkTicketMutationHookResult = ReturnType<typeof useCreateDeviceLinkTicketMutation>;
export type CreateDeviceLinkTicketMutationResult = ApolloReactCommon.MutationResult<CreateDeviceLinkTicketMutation>;
export type CreateDeviceLinkTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>;
export const GetDeviceContextDocument = gql`
    query GetDeviceContext($id: ID!) {
  deviceContext(id: $id) {
    active
    validLicense
    licenseErrorMessage
    organizationName
    initialPresentation
    controller {
      id
      presentations {
        id
        name
        pages {
          id
          name
          components {
            transform
            transformOrigin
            height
            width
            horizontalAlign
            verticalAlign
            zIndex
            page {
              id
            }
            component {
              id
              name
              componentType
              configuration
              style
              classNames
              identifier
              assets {
                id
                assetType
                mimeType
                lastShortTermReadToken
              }
              dataSource {
                id
                name
                dataSourceType
                configuration
                assets {
                  id
                  assetType
                  mimeType
                  lastShortTermReadToken
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type GetDeviceContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDeviceContextQuery, GetDeviceContextQueryVariables>, 'query'> & ({ variables: GetDeviceContextQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDeviceContextComponent = (props: GetDeviceContextComponentProps) => (
      <ApolloReactComponents.Query<GetDeviceContextQuery, GetDeviceContextQueryVariables> query={GetDeviceContextDocument} {...props} />
    );
    
export type GetDeviceContextProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDeviceContextQuery, GetDeviceContextQueryVariables> & TChildProps;
export function withGetDeviceContext<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDeviceContextQuery,
  GetDeviceContextQueryVariables,
  GetDeviceContextProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDeviceContextQuery, GetDeviceContextQueryVariables, GetDeviceContextProps<TChildProps>>(GetDeviceContextDocument, {
      alias: 'getDeviceContext',
      ...operationOptions
    });
};

/**
 * __useGetDeviceContextQuery__
 *
 * To run a query within a React component, call `useGetDeviceContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceContextQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceContextQuery, GetDeviceContextQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeviceContextQuery, GetDeviceContextQueryVariables>(GetDeviceContextDocument, baseOptions);
      }
export function useGetDeviceContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceContextQuery, GetDeviceContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeviceContextQuery, GetDeviceContextQueryVariables>(GetDeviceContextDocument, baseOptions);
        }
export type GetDeviceContextQueryHookResult = ReturnType<typeof useGetDeviceContextQuery>;
export type GetDeviceContextLazyQueryHookResult = ReturnType<typeof useGetDeviceContextLazyQuery>;
export type GetDeviceContextQueryResult = ApolloReactCommon.QueryResult<GetDeviceContextQuery, GetDeviceContextQueryVariables>;
export const ParkgardenMonthActivitiesDocument = gql`
    query ParkgardenMonthActivities {
  scrapers {
    nassjokommun {
      monthActivities {
        title
        startTime
        endTime
        content
      }
    }
  }
}
    `;
export type ParkgardenMonthActivitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>, 'query'>;

    export const ParkgardenMonthActivitiesComponent = (props: ParkgardenMonthActivitiesComponentProps) => (
      <ApolloReactComponents.Query<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables> query={ParkgardenMonthActivitiesDocument} {...props} />
    );
    
export type ParkgardenMonthActivitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables> & TChildProps;
export function withParkgardenMonthActivities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ParkgardenMonthActivitiesQuery,
  ParkgardenMonthActivitiesQueryVariables,
  ParkgardenMonthActivitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables, ParkgardenMonthActivitiesProps<TChildProps>>(ParkgardenMonthActivitiesDocument, {
      alias: 'parkgardenMonthActivities',
      ...operationOptions
    });
};

/**
 * __useParkgardenMonthActivitiesQuery__
 *
 * To run a query within a React component, call `useParkgardenMonthActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useParkgardenMonthActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParkgardenMonthActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useParkgardenMonthActivitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>(ParkgardenMonthActivitiesDocument, baseOptions);
      }
export function useParkgardenMonthActivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>(ParkgardenMonthActivitiesDocument, baseOptions);
        }
export type ParkgardenMonthActivitiesQueryHookResult = ReturnType<typeof useParkgardenMonthActivitiesQuery>;
export type ParkgardenMonthActivitiesLazyQueryHookResult = ReturnType<typeof useParkgardenMonthActivitiesLazyQuery>;
export type ParkgardenMonthActivitiesQueryResult = ApolloReactCommon.QueryResult<ParkgardenMonthActivitiesQuery, ParkgardenMonthActivitiesQueryVariables>;
export const ParkgardenWeekLunchDocument = gql`
    query ParkgardenWeekLunch {
  scrapers {
    nassjokommun {
      weekLunchMenus {
        title
        number
        days {
          nameOfDay
          lunch
          alternative
          dessert
        }
      }
    }
  }
}
    `;
export type ParkgardenWeekLunchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>, 'query'>;

    export const ParkgardenWeekLunchComponent = (props: ParkgardenWeekLunchComponentProps) => (
      <ApolloReactComponents.Query<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables> query={ParkgardenWeekLunchDocument} {...props} />
    );
    
export type ParkgardenWeekLunchProps<TChildProps = {}> = ApolloReactHoc.DataProps<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables> & TChildProps;
export function withParkgardenWeekLunch<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ParkgardenWeekLunchQuery,
  ParkgardenWeekLunchQueryVariables,
  ParkgardenWeekLunchProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables, ParkgardenWeekLunchProps<TChildProps>>(ParkgardenWeekLunchDocument, {
      alias: 'parkgardenWeekLunch',
      ...operationOptions
    });
};

/**
 * __useParkgardenWeekLunchQuery__
 *
 * To run a query within a React component, call `useParkgardenWeekLunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useParkgardenWeekLunchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParkgardenWeekLunchQuery({
 *   variables: {
 *   },
 * });
 */
export function useParkgardenWeekLunchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>) {
        return ApolloReactHooks.useQuery<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>(ParkgardenWeekLunchDocument, baseOptions);
      }
export function useParkgardenWeekLunchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>(ParkgardenWeekLunchDocument, baseOptions);
        }
export type ParkgardenWeekLunchQueryHookResult = ReturnType<typeof useParkgardenWeekLunchQuery>;
export type ParkgardenWeekLunchLazyQueryHookResult = ReturnType<typeof useParkgardenWeekLunchLazyQuery>;
export type ParkgardenWeekLunchQueryResult = ApolloReactCommon.QueryResult<ParkgardenWeekLunchQuery, ParkgardenWeekLunchQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }

      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "DeviceMessage",
        "possibleTypes": [
          {
            "name": "ReloadCommand"
          },
          {
            "name": "SetLocalStorageValueCommand"
          },
          {
            "name": "SetPresentationCommand"
          },
          {
            "name": "UnlinkCommand"
          },
          {
            "name": "ToggleStatusbarCommand"
          },
          {
            "name": "ToggleDebugPanelCommand"
          },
          {
            "name": "RefreshContextCommand"
          },
          {
            "name": "RequestScreenShotCommand"
          },
          {
            "name": "DeviceMessageCommand"
          }
        ]
      }
    ]
  }
};

      export default result;
    