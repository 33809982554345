// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment-timezone';
import {ICalendarComponentConfiguration} from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { CSSPropertiesToString, loadComponent } from '../api/util';
import useCalendarDataSource from '../datasources/CalendarDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';
import renderTemplate from '../api/renderTemplate';

// Defaults
const defaultConfig = {
  dateFormatString: 'MMM D HH:mm',
  numberOfItemsToShow: 10
} as ICalendarComponentConfiguration;

const defaultStyle = {
} as CSSProperties;

// Component
export interface IRssComponentProps {
  pageComponent: PageComponent
}

const CalendarComponent = (props: IRssComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useCalendarDataSource(pageComponent.component!.dataSource);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = async () => {

      // Draw data on top
      if (!data) return;

      if (context.template)
      {
        const output = await renderTemplate(context.template, data, { timeFormat: context.config.dateFormatString });

        d3.select(context.selector)
          .html(output)
          .attr("style", CSSPropertiesToString(context.style));

      } else {
        const config = context.config;
        const columns = ['startDate', 'summary'];

        const table = d3.select(context.selector)
          .append("table")
          .attr("style", CSSPropertiesToString(context.style));

        const tableBody = table.append("tbody");
        const rows = tableBody
          .selectAll("tr")
          .data(data.items.slice(0, config.numberOfItemsToShow))
          .enter()
          .append("tr");

        // We built the rows using the nested array - now each row has its own array.
        rows.selectAll("td")
        // each row has data associated; we get it and enter it for the cells.
          .data((d: any) => columns.map((c: string) => {
            if (c === 'startDate')
              return moment(d[c]).format(config.dateFormatString);
            else
              return d[c];
          }))
          .enter()
          .append("td")
          .text((d: any) => {
            return d;
          });
      }
    };

    draw();
  }, [ data, context.config, context.style, context.selector, context.template]);

  return renderComponentDiv(context);
};

export default CalendarComponent;
