// NPM
import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';

// Local
import deviceRootMachine, {
  RootDeviceContext,
  RootDeviceEvent
} from '../services/DeviceRootMachine';

// Assets
import DebugPanel from '../components/builtin/DebugPanel';
import RenderRoot from './RenderRoot';

// Render device machine state
const PixonDevice = () => {
  const [current, send, service] = useMachine<RootDeviceContext, RootDeviceEvent>(deviceRootMachine);

  useEffect(() => {
    service.onTransition((state, event) => console.log('onTransition', state, event));
  }, [service]);

  return <>
      <RenderRoot current={current} send={send} service={service} />
      <DebugPanel current={current} send={send} service={service} />
    </>;
};

export default PixonDevice;

