// NPM
import React, { ReactNode } from 'react';

// Local

// Assets
import logo from '../../assets/img/logos/pixon-logo-white-transparent-text.svg';

export interface ILogoWithSubtitleProps {
  subtitle?: string;
  children?: ReactNode;
}

// Render device machine state
const LogoWithSubtitle = (props: ILogoWithSubtitleProps) => {
  return <div className="center" style={{width: '100vw' }} >
        <img src={logo} alt='Logotype' style={{width: '30vw'}}/>
        <div className="subtitle">{props.subtitle}</div>
        {props.children}
        <div>
          <a className="subtitle" href="https://pixon.app" id='pixon-heading-url'>pixon.cloud</a>
        </div>
    </div>;
};

export default LogoWithSubtitle;
