// NPM
import { CSSProperties, useEffect, useReducer, useState } from 'react';
import { IKeyboardBarcodeReaderComponentConfiguration } from 'pixon-component-core';

// Local
import { DeviceMessageEnvelope, PageComponent } from '../graphql/types';
import { loadComponent, readIotConfig } from '../api/util';

// Defaults
const defaultConfig = {
  acceptBufferDelay: 300
} as IKeyboardBarcodeReaderComponentConfiguration;

const defaultStyle = {
} as CSSProperties;

// Component
export interface IKeyboardBarcodeReaderComponentProps {
  pageComponent: PageComponent,
  connectionSend: any
}

interface GlobalKeyAction {
  type: string,
  key?: string
}

interface BarcodeState {
  buffer: string
}

const initialState = {
  buffer: ''
} as BarcodeState;

const reducer = (state:BarcodeState, action:GlobalKeyAction):BarcodeState => {
  switch (action.type) {
    case 'kp':
      return {
        buffer: state.buffer + action.key
      };
    case 'clear':
    default:
      return initialState;
  }
};

const KeyboardBarcodeReaderComponent = (props: IKeyboardBarcodeReaderComponentProps) => {
  const { pageComponent, connectionSend } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [barcodeState, dispatch] = useReducer(reducer, initialState);

  // Attach key listeners on component mount
  useEffect(() => {
    const handleKeyDown = (event:any) => {
      // Add keystroke and timestamp to buffer on global keystrokes
      dispatch({type: 'kp', key: event.key });
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Every time the buffer changes, trigger a timer that will
  // set the barcode value when keystrokes stop coming in
  useEffect(() => {
    const onDetected = (result:string) => {
      console.log('detected barcode from keyboard', result);
      const cfg = readIotConfig();
      const dataSourceId = pageComponent?.component?.dataSource?.id ?? '';
      const cmd = {
        from: {
          componentId: pageComponent.component!.id,
          deviceId: cfg.deviceId,
          iotId: cfg.deviceIotId
        },
        to: {
          deviceId: "00000000-0000-0000-0000-000000000000",
          componentId: dataSourceId,
          iotId: ''
        },
        message: {
          name: "DeviceGarbageProductCategoryQuery",
          productIdentifier: result,
          responseTargetDataSource: dataSourceId
        }
      } as DeviceMessageEnvelope;

      if (dataSourceId && result)
      {
        console.log('sending command', cmd);
        connectionSend('SEND_MESSAGE', { data: cmd } );
      } else {
        console.log('detected barcode, but no target datasource configured');
      }
    };

    const timer = setTimeout(() => {
      if (barcodeState.buffer && barcodeState.buffer.length > 0)
      {
        // Send barcode query
        onDetected(barcodeState.buffer);

        // Clear buffer
        dispatch({type: 'clear' })
      }
    }, context.config.acceptBufferDelay);

    return () => clearTimeout(timer);
  }, [barcodeState, context.config, connectionSend, pageComponent]);

  return null;
};

export default KeyboardBarcodeReaderComponent;
