// Npm
import React, { CSSProperties, useState } from 'react';
// Local
import { Asset, PageComponent } from '../graphql/types';
import { getAssetUrl, loadComponent } from '../api/util';
import { ComponentDiv } from '../views/DeviceComponent';
import { IImageCarouselComponentConfiguration } from 'pixon-component-core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

interface IImageComponentConfiguration {
}

const defaultConfig = {
  interval: 3000,
  showThumbs: false,
  showStatus: false,
  showIndicators: false,
  showArrows: false,
  infiniteLoop: true,
  autoPlay: true,
  transitionTime: 200
} as IImageCarouselComponentConfiguration;

const defaultStyle = {
} as CSSProperties;

export interface IImageCarouselComponentProps {
  pageComponent: PageComponent,
}

const ImageCarouselComponent = (props: IImageCarouselComponentProps) => {
  const {pageComponent} = props;
  const component = pageComponent.component!;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  const imageAssets = component.assets && component.assets.length > 0 ? component.assets as Asset[] : [];
  const items = imageAssets.map(a => <div key={a.id!}>
    <img src={getAssetUrl(a)} alt={a.displayName!} />
  </div>);

  return <ComponentDiv context={context}>
    <Carousel {...context.config} className="presentation-mode">
      {items}
    </Carousel>
  </ComponentDiv>;
};

export default ImageCarouselComponent;
