// NPM
import React from 'react';

// Local

// Assets
import { useService } from '@xstate/react';
import { IotConfigurationContext } from '../../services/IotConfigurationMachine';
import QRCode from 'qrcode.react';

export interface ILinkCodeLabelProps {
  service: any;
}

// Render device machine state
const LinkCodeLabel = (props: ILinkCodeLabelProps) => {
  const [current] = useService(props.service);
  const context = current.context as IotConfigurationContext;

  return <>
    <QRCode
      value={`${window.QR_CODE_BASE_URL}link/${context.linkCode}`}
      size={'16vw'}
      bgColor={"#ffffff"}
      fgColor={"#000000"}
      level={"L"}
      includeMargin={true}
      renderAs={"svg"}
    />
    <div id="link-code">
      <p>
        {context.linkCode}
      </p>
    </div>
  </>
};

export default LinkCodeLabel;
