// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import 'moment/locale/sv';
import { ISmhiWeatherConfiguration } from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { getAssetUrl, loadComponent } from '../api/util';
import {
  IDayForecast
} from '../datasources/SmhiWeatherForecastDataSource';
import useSmhiWeatherDataSource from '../datasources/SmhiWeatherForecastDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';

const moment = require('moment');
moment.locale('sv');

// Defaults
const defaultConfig = {
  numDaysToShow: 7,
  iconSize: 60,
  showDayLabel: true,
  showTemperatureLabel: true
} as ISmhiWeatherConfiguration;

const defaultStyle = {
} as CSSProperties;

// Component
export interface ISmhiWeatherComponentProps {
  pageComponent: PageComponent
}

const SmhiWeatherComponent = (props: ISmhiWeatherComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const component = pageComponent.component!;
  const iconPackAsset =
    component
    && component.assets
    && component.assets.length > 0
      ? component.assets[0]
      : undefined;

  const {config} = context;
  const {iconSize, numDaysToShow, showDayLabel, showTemperatureLabel} = config;

  const [data] = useSmhiWeatherDataSource(component.dataSource);
  const [imageUrl, setImageUrl] = useState();

  // Fetch icons
  useEffect(() => {
    if (iconPackAsset)
    {
      setImageUrl(getAssetUrl(iconPackAsset));
    }
  }, [iconPackAsset]);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html("");

    const draw = () => {
      // Draw data on top
      if (!data || !imageUrl) return;

      const foreCastDiv = d3.select(context.selector)
        .append("div");

      d3.xml(imageUrl)
        .then((documentFragment:any) => {
          const svgNode = documentFragment
            .getElementsByTagName("svg")[0];

          if (!svgNode)
            return;

          _.map(data.forecasts, (forecast: IDayForecast, index:number) => {
            if (index + 1 > numDaysToShow)
              return;

            const dayDiv = foreCastDiv
              .append("div")
              .attr("style", `display: inline-block; width: ${iconSize}px; text-align: center`);

            if (showDayLabel) {
              dayDiv
                .append("div")
                .text(moment(forecast.date).locale('sv').format('dd DD').toUpperCase())
                .attr("style", "padding: 5px; text-align: center;");
            }

            const icon =
              svgNode
                .getElementById('smhi' + forecast.icon)
                .cloneNode(true);

            const svgIconNode = dayDiv
              .append('svg')
              .attr("viewBox", `0 0 ${iconSize} ${iconSize}`)
              .style("width", `${iconSize}px`)
              .style("height", "auto");

            svgIconNode
              .node()!
              .appendChild(icon);

            svgIconNode
              .select('#smhi' + forecast.icon)
              .attr('width', iconSize)
              .attr('height', iconSize);

            if (showTemperatureLabel)
            {
              dayDiv
                .append("div")
                .attr("style", `text-align: center;`)
                .text(Math.round(forecast.tempHigh).toString()+'°');
            }
          });
        });
    };

    draw();
  }, [data, imageUrl, numDaysToShow, iconSize, showTemperatureLabel, showDayLabel, context.selector]);

  return renderComponentDiv(context);
};

export default SmhiWeatherComponent;
