import { Asset } from '../graphql/types';
import { getAssetUrl } from './util';
import Handlebars from "handlebars";
import moment from 'moment-timezone';
import groupBy from 'handlebars-group-by';


export interface ITemplateHelperConfig {
  timeFormat: string
}

export function renderTemplateString(template: string, data: any) {
  const compiledTemplate = Handlebars.compile(template);
  return compiledTemplate(data);
}

async function renderTemplate(template: Asset, data: any, config?: ITemplateHelperConfig) {
  const templateString = await fetch(getAssetUrl(template))
    .then(r => r.text());

  // limit an array to a maximum of elements (from the start)
  // See https://stackoverflow.com/questions/10377700/limit-results-of-each-in-handlebars-js
  // Usage: {{#each (limit cart.products 5)}}
  //   <li>Index is {{@index}} - element is {{this}}</li>
  // {{/each}}
  Handlebars.registerHelper('limit', function (arr, limit) {
    if (!Array.isArray(arr)) { return []; }
    return arr.slice(0, limit);
  });

  Handlebars.registerHelper('formatDate', function(instr) {
    return moment(instr).calendar(moment(),
      {
        lastDay: '[Igår]',
        sameDay: '[Idag]',
        nextDay: '[Imorgon]',
        lastWeek: '[förra] dddd',
        nextWeek: 'dddd',
        sameElse: 'dddd DD MMMM',
      });
  });
  Handlebars.registerHelper('formatTime', function(instr) {
    return moment(instr).format(config && config.timeFormat);
  });

  Handlebars.registerHelper("within_minutes", function(this : any,dateString,time,timezone,options) {
    if(moment(dateString).tz(timezone).isAfter(moment().tz(timezone).subtract(time,"minutes"))){
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  type mathOperators = '+' | '-' | '*' | '/' | '%';
  Handlebars.registerHelper("math", function(lvalue : number, operator : mathOperators, rvalue : number, options) {
    return {
      "+": lvalue + rvalue,
      "-": lvalue - rvalue,
      "*": lvalue * rvalue,
      "/": lvalue / rvalue,
      "%": lvalue % rvalue
    }[operator];
  });

  Handlebars.registerHelper('ifCond', function (this : any,v1, operator, v2, options) {

    switch (operator) {
      case '==':
        // eslint-disable-next-line
        return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '!=':
        // eslint-disable-next-line
        return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '!==':
        return (v1 !== v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });


  groupBy(Handlebars);
  const compiledTemplate = Handlebars.compile(templateString);
  return compiledTemplate(data);
}

export default  renderTemplate;
