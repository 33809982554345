// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { IClockConfiguration } from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';
import useClockDataSource from '../datasources/ClockDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';

// Defaults
const defaultConfig = {
  formatString: 'dddd, MMMM D YYYY, HH:mm:ss'
} as IClockConfiguration;

const defaultStyle = {
  textTransform: 'uppercase'
} as CSSProperties;

// Component
export interface ITextClockComponentProps {
  pageComponent: PageComponent,
}

const TextClockComponent = (props: ITextClockComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useClockDataSource(pageComponent.component!.dataSource);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');
    const draw = () => {
      if (!data || !context.config || !context.selector) return;
      const timeString = data.currentTime.format(context.config.formatString);

      d3.select(context.selector)
        .append("p")
        .text(timeString);
    };

    draw();
  }, [data, context.config, context.selector]);

  return renderComponentDiv(context);
};

export default TextClockComponent;
