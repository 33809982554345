// Npm
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { Asset, PageComponent } from '../graphql/types';
import { getAssetUrl, loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

interface IImageComponentConfiguration {
}

const defaultConfig = {
} as IImageComponentConfiguration;

const defaultStyle = {
} as CSSProperties;


export interface IImageComponentProps {
  pageComponent: PageComponent,
}

const ImageComponent = (props: IImageComponentProps) => {
  const {pageComponent} = props;
  const component = pageComponent.component!;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  // Pick first asset for now, but later on we could compose backgrounds from
  // more assets (such as different resolutions, videos, etc)
  const image = component.assets && component.assets.length > 0 ? component.assets[0] as Asset : undefined;

  // Redraw background on image url change
  useEffect(() => {
    if (!image)
      return;

    const imageUrl = getAssetUrl(image);

    // Clear drawing
    d3.select(context.selector).html('');

    const drawSvg = (url: string) => {
      const mainSvg = d3.select(context.selector)
        .append('svg');

      d3.xml(url)
        .then((documentFragment:any) => {
          const svgNode = documentFragment
            .getElementsByTagName("svg")[0];

          mainSvg.node()!
            .appendChild(svgNode)
            .attr("width", pageComponent.width ? pageComponent.width : 100)
            .attr("height", pageComponent.height ? pageComponent.height : 100);
        });
    };

    const drawRaster = (url: string) => {
      d3.selectAll(context.selector)
        .append("img")
        .attr("src", url)
        .attr("width", pageComponent.width ? pageComponent.width : 100)
        .attr("height", pageComponent.height ? pageComponent.height : 100);
    };

    // Redraw
    if (image.mimeType === 'image/svg+xml') {
      drawSvg(imageUrl);
    } else {
      drawRaster(imageUrl);
    }
  }, [image, context.selector, pageComponent.height, pageComponent.width]);

  return renderComponentDiv(context);
};

export default ImageComponent;
