// NPM
import { useEffect, useState } from 'react';

// Local
import { DataSource } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';

// Defaults

// Component
export interface IDeviceEventData {
  payload: string,
  timestamp: Date
}

export default function useDeviceEventDataSource(renderContext: RenderContext, dataSource?: DataSource | null) {
  const [data, setData] = useState<IDeviceEventData>();

  useEffect(() => {
    if (!renderContext || !dataSource)
      return;

    const messageEvent = renderContext ? renderContext.componentMessage : undefined;

    if (!messageEvent || messageEvent.targetDataSource !== dataSource.id)
      return;

    setData({
      payload: messageEvent.payload,
      timestamp: new Date(),
    });
  }, [renderContext, dataSource]);

  return [data];
}
