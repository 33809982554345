// NPM
import React, { useEffect, useState } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { useService } from '@xstate/react';

// Local
import { RenderContext } from '../services/RenderMachine';
import StatusBar from '../components/builtin/StatusBar';
import ConnectionIcon from '../components/builtin/ConnectionIcon';
import RenderMain from './RenderMain';
import LogoWithSubtitle from '../components/builtin/LogoWithSubtitle';
import {
  ConfiguredMachineContext,
} from '../services/ConfiguredMachine';
import { DeviceContext } from '../graphql/types';

export interface IRenderConfiguredMachineProps {
  service: any;
}

// Render device machine state
const RenderConfiguredMachine = (props: IRenderConfiguredMachineProps) => {
    const [current] = useService(props.service);

  // Getting the invokde child machines like this becomes stale on second
  // invokation (then its a new object). This causes for example
  // the connection icon not to hide upon reconnect (since second invocation
  // is a new machine and the update in state is not caught by react.
  // See: https://github.com/davidkpiano/xstate/issues/424
  const renderService = props.service ? props.service.children.get('renderer') : undefined;
  const [currentRenderState] = useService(renderService);
  const [renderContext, setRenderContext] = useState(currentRenderState.context as RenderContext);
  const [deviceContext, setDeviceContext] = useState(renderContext.context as DeviceContext);

  useEffect(() => {
    setRenderContext(currentRenderState.context as RenderContext);
    setDeviceContext(renderContext.context as DeviceContext);
  }, [currentRenderState, renderContext], );

  const [connectionService, setConnectionService] = useState<any>( props.service.children.get('connectionHandler'));
  useEffect(() => {
    setConnectionService(props.service.children.get('connectionHandler'));
  }, [props.service]);

  //const connectionService = props.service ? props.service.children.get('connectionHandler') : undefined;
  const [currentConnectionState, send] = useService(connectionService);

  return (
    <Stack verticalFill>

      <Stack.Item grow={2}>
        <ConnectionIcon isConnected={(current.context as ConfiguredMachineContext).isConnected} showWhenConnected={false} />

        {renderContext.context &&
          <RenderMain renderContext={renderContext} connectionSend={send}/>
        }

        {!renderContext.context &&
            <LogoWithSubtitle subtitle='Retrieving display content...' />
        }


      </Stack.Item>

      {renderContext.showStatusBar &&
        <Stack.Item>
          <StatusBar deviceContext={deviceContext} connectionState={currentConnectionState}/>
        </Stack.Item>
      }

    </Stack>
  );
};

export default RenderConfiguredMachine;
