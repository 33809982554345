// NPM
import React, { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';

// Defaults
const defaultConfig = {
  label: 'Text label'
};

const defaultStyle = {
} as CSSProperties;

// Component
export interface IDefaultComponentProps {
  pageComponent: PageComponent,
}

const TextLabelComponent = (props: IDefaultComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = () => {
      if (!context.config || !context.selector) return;

      d3.select(context.selector)
        .append("p")
        .text(context.config.label);
    };

    draw();
  }, [pageComponent.component, context.config, context.selector]);

  return <div id={context.id} style={context.style}  />;
};

export default TextLabelComponent;
