// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent, ScheduledActivity, useParkgardenMonthActivitiesQuery } from '../graphql/types';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

// Defaults
const defaultConfig = {
  dateOptions: { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  pollIntervalInMinutes: 5,
  locale: 'sv-Se',
};

const defaultStyle = {
} as CSSProperties;

// Component
export interface ITextParkgardenActivitiesComponentProps {
  pageComponent: PageComponent,
}

// Render device machine state
const TextParkgardenActivitiesComponent = (props: ITextParkgardenActivitiesComponentProps) => {
  const {pageComponent} = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const { data, loading, error } = useParkgardenMonthActivitiesQuery({
    pollInterval: 1000*60*context.config.pollIntervalInMinutes
  });

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html("");

    if (!data)
      return;

    const activities = data.scrapers.nassjokommun.monthActivities as ScheduledActivity[];
    if (!activities || activities.length < 1)
      return;

    const draw = (activity: ScheduledActivity) => {
      d3.select(context.selector)
        .append("p")
        .text(new Date(activity.startTime).toLocaleString(context.config.locale, context.config.dateOptions));

      d3.select(context.selector)
        .append("p")
        .text(activity.title);
    };

    draw(activities[0]);
  }, [data, context.config, context.selector]);

  if (loading || error)
    return null;

  return renderComponentDiv(context);
};

export default TextParkgardenActivitiesComponent;
