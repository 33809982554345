// NPM
import { useEffect, useState } from 'react';
import useInterval from '@use-it/interval';
import { INameOfDayDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { http, loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  providerName:'MinNamsdag.se',
  refreshIntervalInMinutes: 5,
} as INameOfDayDataSourceConfiguration;


// Component
export interface INameOfDayData {
  names: string[]
}

export default function useNameOfDayDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<INameOfDayData>({ names: ['Name1','Name2']});

  // Initial load after 5 second
  const [delay, setDelay] = useState(5000);
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  useInterval(async () => {

      //Fetch data here from provider API
      const today=new Date();
      const dataUrl = `https://api.dryg.net/dagar/v2.1/${today.getFullYear()}/${today.getMonth()+1}/${today.getDay()}`
      const dates = await http(dataUrl);

      setData({
        names: dates?.dagar?.[0]?.namnsdag
      });

      // Refetch at configured interval, never quicker than 5 minutes
      setDelay(1000*60*Math.max(5, config.refreshIntervalInMinutes));
  }, delay);

  // Fetch on initial load
  useEffect(() => {
    async function loadNames() {

      //Fetch data here from provider API
      const today=new Date();
      const dataUrl = `https://api.dryg.net/dagar/v2.1/${today.getFullYear()}/${today.getMonth()+1}/${today.getDay()}`
      const dates = await http(dataUrl);

      setData({
        names: dates?.dagar?.[0]?.namnsdag
      });

    }
    loadNames();

  }, [config]);


  return [data];
}
