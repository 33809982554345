import { CSSProperties } from 'react';
import CryptoJS from 'crypto-js';
import html2canvas, { Options } from 'html2canvas';
import {
  Aborter,
  AnonymousCredential,
  BlockBlobURL,
  StorageURL,
  uploadBrowserDataToBlockBlob,
} from '@azure/storage-blob';
import _ from 'lodash';

import { IoTConfig } from '../services/IotConfigurationMachine';
import { Asset, AssetType, DataSource, PageComponent } from '../graphql/types';

export const isEmpty = (s?: string | null | undefined) =>  s === undefined || s === null || !s || !s.length;

export const readIotConfig = (): IoTConfig => {
  const configStr = localStorage.getItem('iotConfig');

  return configStr
    ? JSON.parse(configStr)
    : undefined;
};

export const readDeviceId = () => {
  const cfg = readIotConfig();

  return cfg
    ? cfg.deviceId
    : undefined;
};


export const http = async (request: RequestInfo): Promise<any> => {
    return new Promise(resolve => {
        return fetch(request)
            .then(response => response.json())
            .then(body => {
                resolve(body);
            })
          .catch(r => {
            return null;
          });
    });
};

export const getAssetUrl = (asset: Asset): string =>
  `${window.CONTENT_BASE_URL}assets/${asset.id}${asset.lastShortTermReadToken}`;

export const filterByKeys = (o: any, allowed: Array<string>) => Object.keys(o)
  .filter(key => allowed.includes(key))
  .reduce((obj:any, key) => {
    obj[key] = o[key];
    return obj;
  }, {});

export const encodeUriComponentStrict = (str: string):string => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};

export const getSaSToken = (hostName: string, deviceId: string, sharedKey: string): string => {
  var sr = encodeUriComponentStrict(hostName + '/devices/' + deviceId);
  var se = Math.round(new Date().getTime() / 1000) + 24 * 3600;
  var StringToSign = sr + '\n' + se;
  var sig = encodeUriComponentStrict(CryptoJS.HmacSHA256(StringToSign, CryptoJS.enc.Base64.parse(sharedKey)).toString(CryptoJS.enc.Base64));
  return 'SharedAccessSignature sr=' + sr + '&sig=' + sig + '&se=' + se;
};

const getThumbnail = (original:any, scale:any) => {
  var canvas = document.createElement("canvas");

  canvas.width = original.width * scale;
  canvas.height = original.height * scale;

  canvas.getContext("2d")!.drawImage(original, 0, 0, canvas.width, canvas.height);

  return canvas
};

export const uploadScreenshot = async (imageSasWriteUrl: string, thumbnailSasWriteUrl: string) => {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  const opts =  {
    backgroundColor: '#ffffff',
    foreignObjectRendering: true,
    logging: false,
    removeContainer: false,
    width: w,
    height: h
  } as Options;

  let rootElement = document.getElementById("root");

  await html2canvas(rootElement!, opts).then(function(canvas) {
    const anonymousCredential = new AnonymousCredential();
    canvas.toBlob(async renderedBlob => {
      // Use AnonymousCredential when url already includes a SAS signature
      const imageBlobUrl = new BlockBlobURL(imageSasWriteUrl, StorageURL.newPipeline(anonymousCredential));
      console.log('uploading image', imageBlobUrl);
      await uploadBrowserDataToBlockBlob(Aborter.none, renderedBlob!, imageBlobUrl, {
            blockSize: 4 * 1024 * 1024, // 4MB block size
            parallelism: 20, // 20 concurrency
            blobHTTPHeaders: {
              blobContentType: 'image/png'
            },
            //progress: (ev:any) => console.log(ev)
      });
      console.log('Screenshot uploaded', imageSasWriteUrl);
    });

    const thumbNailCanvas = getThumbnail(canvas, 1/6);
    thumbNailCanvas.toBlob(async renderedBlob => {
      const thumbnailBlobUrl = new BlockBlobURL(thumbnailSasWriteUrl, StorageURL.newPipeline(anonymousCredential));
      console.log('uploading thumbnail', thumbnailBlobUrl);
      await uploadBrowserDataToBlockBlob(Aborter.none, renderedBlob!, thumbnailBlobUrl, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        parallelism: 20, // 20 concurrency
        blobHTTPHeaders: {
          blobContentType: 'image/png'
        },
        //progress: (ev:any) => console.log(ev)
      });
      console.log('Thumbnail uploaded', imageSasWriteUrl);
    });
  }).catch(r => console.log('render error', r));
};

export const median = (values: Array<number>): number => {
  values.sort( function(a,b) {return a - b;} );
  var half = Math.floor(values.length/2);

  if(values.length % 2)
    return values[half];
  else
    return (values[half-1] + values[half]) / 2.0;
};

export const CSSPropertiesToString = (style: CSSProperties) => {
  let cssString = "";
  for (let objectKey in style) {
    cssString += CSSPropertyNameToCSSString(objectKey) + ": " + style[objectKey as keyof CSSProperties] + ";\n";
  }

  return cssString;
};

export const CSSPropertyNameToCSSString = (name: string) =>
  name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);

export interface IComponentRenderContext {
  id: string,
  selector: string,
  style: CSSProperties,
  config: any,
  transform: string,
  template: Asset | null | undefined,
  pageComponent: PageComponent
}

export const identityMatrix  = [
  1, 0, 0, 0,
  0, 1, 0, 0,
  0, 0, 1, 0,
  0, 0, 0, 1,
];

export const loadComponent = (pageComponent: PageComponent, defaultStyle?: CSSProperties, defaultConfig?: any): IComponentRenderContext => {
  const component = pageComponent.component!;
  const config = loadConfig(component.configuration, defaultConfig);
  const style = loadStyle(component.style, defaultStyle, pageComponent);
  const id = _.uniqueId(component.componentType!.replace('_','-').toLowerCase());

  let template:Asset | undefined | null = undefined;
  if (component.assets && component.assets.length > 0)
  {
    const templates = component.assets.filter(a => a!.assetType === AssetType.HandlebarsTemplate);
    template = templates && templates.length > 0 ? templates[0] : undefined;
  }

  return {
    id: id,
    selector: '#' + id,
    config: config,
    style: style,
    transform: calcTransform(pageComponent),
    template: template,
    pageComponent: pageComponent
  };
};

export const calcTransform = (pageComponent?: PageComponent) => {
  if (!pageComponent || !pageComponent.transform)
    return `matrix3d(${identityMatrix.join(",")})`;

  return `${pageComponent.transform}`;
};

export const loadStyle = (style?: string | null, defaultStyle?: CSSProperties, pageComponent?: PageComponent) => {
  const parsed = JSON.parse(style ? style : '{}');

  // Return parsed style merged with default style
  // with precedence to parsed style
  return {
    ...defaultStyle,
    ...parsed,
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    width: pageComponent ? `${pageComponent.width}px` : undefined,
    height: pageComponent ? `${pageComponent.height}px` : undefined,
    //transformOrigin: pageComponent && pageComponent.transformOrigin ? pageComponent.transformOrigin : 'top left',
    transform: calcTransform(pageComponent)
  };
};

export const loadDataSourceConfig = (dataSource?: DataSource | null, defaultConfig?: any) => {
  return loadConfig(dataSource ? dataSource.configuration : undefined, defaultConfig);
};

export const loadConfig = (configuration?: string | null, defaultConfig?: any) => {
  const parsedConfig = JSON.parse(!isEmpty(configuration) ? configuration! : '{}');
  // Return parsed config merged with default config
  // with precedence to parsed config
  return {
    ...defaultConfig,
    ...parsedConfig
  };
};
