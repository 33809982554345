// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { IDeviceEventActionComponentConfiguration } from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';
import useDeviceEventDataSource from '../datasources/DeviceEventDataSource';
import { renderTemplateString } from '../api/renderTemplate';

// Defaults
const defaultConfig = {
  eventReceivedShowSelector: '',
  eventReceivedHideSelector: '',

  timeoutShowSelector: '',
  timeoutHideSelector: '',

  timeoutInSeconds: 5,
} as IDeviceEventActionComponentConfiguration;

const defaultStyle = {} as CSSProperties;

// Component
export interface IDeviceEventComponentProps {
  pageComponent: PageComponent,
  renderContext: RenderContext
}

const clear = (selector: string) => d3.select(selector).html('');

const DeviceEventComponent = (props: IDeviceEventComponentProps) => {
  const { pageComponent, renderContext } = props;
  const component = pageComponent.component!;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useDeviceEventDataSource(renderContext, component.dataSource);

  useEffect(() => {
    d3
      .selectAll('.votebutton')
      .style('visibility', 'hidden');

  }, []);

  useEffect(() => {
    // Clear drawing
    clear(context.selector);

    const draw = async () => {
      if (!context || !data) return;

      let parsedData = data.payload as any;
      try {
        parsedData = JSON.parse(data.payload);
      } catch {

      }

      const eventReceivedShowSelector = renderTemplateString(context.config.eventReceivedShowSelector, parsedData);
      const eventReceivedHideSelector = renderTemplateString(context.config.eventReceivedHideSelector, parsedData);
      const timeoutShowSelector = renderTemplateString(context.config.timeoutShowSelector, parsedData);
      const timeoutHideSelector = renderTemplateString(context.config.timeoutHideSelector, parsedData);

      d3.selectAll(eventReceivedHideSelector)
        .style('visibility', 'hidden');
      d3.selectAll(eventReceivedShowSelector)
        .style('visibility', 'visible');

      setTimeout(() => {
        console.log('timeout hiding', timeoutHideSelector);
        d3.selectAll(timeoutHideSelector)
          .style('visibility', 'hidden');
        d3.selectAll(timeoutShowSelector)
          .style('visibility', 'visible');
      }, context.config.timeoutInSeconds * 1000);

    };

    draw();
  }, [context, data]);

  return renderComponentDiv(context);
};

export default DeviceEventComponent;
