// NPM
import React, { CSSProperties, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { ISendMessageButtonConfiguration } from 'pixon-component-core';

// Local
import { DeviceMessageEnvelope, DeviceMessageCommand, PageComponent } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';
import { loadComponent, readIotConfig } from '../api/util';

// Defaults
const defaultStyle = {
  zIndex: 99999999
} as CSSProperties;

// Component
export interface IDeviceSendMessageButtonComponentProps {
  pageComponent: PageComponent,
  renderContext: RenderContext,
  connectionSend: any
}

// Render device machine state
const DeviceSendMessageButtonComponent = (props: IDeviceSendMessageButtonComponentProps) => {
  const {
    pageComponent,
    connectionSend
  } = props;

  const component = pageComponent.component!;
  const cfg = readIotConfig();
  const dataSource = component.dataSource;
  const defaultConfig = {
    label: 'Etikett',
    message: 'Hello, since ' + new Date().toISOString(),
    to: {
      componentId: dataSource ?  dataSource.id! : ''
    },
    from: {
      componentId: component.id,
      deviceId: cfg.deviceId,
      iotId: cfg.deviceIotId
    }
  } as ISendMessageButtonConfiguration;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  return <div id={context.id} style={context.style}>

    <PrimaryButton
      text={context.config.label}
      disabled={false}
      onClick={() => {
        const cmd = {
          from: {
            componentId: component.id,
            deviceId: cfg.deviceId,
            iotId: cfg.deviceIotId
          },
          to: {
            componentId: dataSource ?  dataSource.id! : '',
            iotId: ''
          },
          message: {
            name: 'DeviceMessageCommand',
            message: context.config.message,
            target: dataSource ? dataSource.id! : ''
          } as DeviceMessageCommand
        } as DeviceMessageEnvelope;

        connectionSend('SEND_MESSAGE', { data: cmd } );
      }}
    />
  </div>
};

export default DeviceSendMessageButtonComponent;
