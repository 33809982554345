import React, { useEffect, useState } from 'react';
import { FontIcon, mergeStyles } from 'office-ui-fabric-react';

//
// PlugConnected;
// PlugDisconnected;

const connectedClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0 25px',
  color: 'green',
  position: 'fixed',
  top: 20,
  left: 10,
  // Connection icon should always be top
  zIndex: 2147483647
});

const disconnectedClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0 25px',
  color: 'red',
  position: 'fixed',
  top: 20,
  left: 10,
  // Connection icon should always be top
  zIndex: 2147483647
});

export interface IConnectionIconProps {
  showWhenConnected: boolean;
  isConnected: boolean;
}


const ConnectionIcon = (props: IConnectionIconProps) => {
  const [isConnected, setConnected] = useState(props.isConnected);
  const shouldShow = !isConnected || props.showWhenConnected;
  const iconName = isConnected
    ? 'PlugConnected'
    : 'PlugDisconnected';

  const currentClass = isConnected
    ? connectedClass
    : disconnectedClass;

  useEffect(() => {
    setConnected(props.isConnected)
  }, [props.isConnected]); // Only rerun if initialDesc changes

  return shouldShow
    ? <FontIcon iconName={iconName} className={currentClass}/>
    : null;
};

export default ConnectionIcon;
