// NPM
import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';

// Local
import PixonDevice from './views/PixonDevice';

initializeIcons();

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={PixonDevice}/>
        </Switch>
      </HashRouter>
    );
  }
}

export default App;


