// NPM
import React, { CSSProperties, useState } from 'react';
import { IBarcodeReaderComponentConfiguration } from 'pixon-component-core';

// Local
import { DeviceMessageEnvelope, PageComponent } from '../graphql/types';
import { loadComponent, readIotConfig } from '../api/util';
import Scanner from './Scanner';

// Defaults
const defaultConfig = {
  readers: ['code_128_reader','ean_reader','ean_8_reader','code_39_reader','code_39_vin_reader','codabar_reader','upc_reader','upc_e_reader','i2of5_reader','2of5_reader','code_93_reader'],
  showPattern: true,
  showFrequency: true,
  drawScanline: true,
  drawBoundingBox: true,
  multiple: false
} as IBarcodeReaderComponentConfiguration;

const defaultStyle = {
} as CSSProperties;

// Component
export interface IBarcodeReaderComponentProps {
  pageComponent: PageComponent,
  connectionSend: any
}

const CameraBarcodeReaderComponent = (props: IBarcodeReaderComponentProps) => {
  const { pageComponent, connectionSend } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const cfg = readIotConfig();

  const scannerConfig = {
    "inputStream": {
      "type": "LiveStream",
      "constraints": {
        "width": { "min": 450 },
        "height": { "min": 300 },
        "facingMode": "user",
        "aspectRatio": { "min": 1, "max": 2 }
      }
    },
    "locator": {
      "patchSize": "medium",
      "halfSample": true
    },
    "numOfWorkers": 2,
    "frequency": 10,
    "decoder": {
      "readers": context.config.readers,
      "multiple": context.config.multiple,
      "debug": {
        "drawBoundingBox": context.config.drawBoundingBox,
        "showFrequency": context.config.showFrequency,
        "drawScanline": context.config.drawScanline,
        "showPattern": context.config.showPattern
      }
    },
    "locate": true
  };

  const onDetected = (result:any) => {
    console.log('detected barcode', result);
    const dataSourceId = pageComponent?.component?.dataSource?.id ?? '';
    const cmd = {
      from: {
        componentId: pageComponent.component!.id,
        deviceId: cfg.deviceId,
        iotId: cfg.deviceIotId
      },
      to: {
        deviceId: "00000000-0000-0000-0000-000000000000",
        componentId: dataSourceId,
        iotId: ''
      },
      message: {
        name: "DeviceGarbageProductCategoryQuery",
        productIdentifier: result,
        responseTargetDataSource: dataSourceId
      }
    } as DeviceMessageEnvelope;

    if (dataSourceId && result)
    {
      console.log('sending command', cmd);
      connectionSend('SEND_MESSAGE', { data: cmd } );
    } else {
      console.log('detected barcode, but no target datasource configured');
    }
  };

  return <div
    id={context.id}
    style={context.style}
    className={`horizontal${context.pageComponent.horizontalAlign!} vertical${context.pageComponent.verticalAlign!} ${context.pageComponent.component!.classNames}`}
  >
    <Scanner onDetected={onDetected} config={scannerConfig} />
  </div>
};

export default CameraBarcodeReaderComponent;
