// Npm
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { ISnowFlakeEffectComponentConfiguration } from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

const Snowflakes = require('magic-snowflakes');
//import { Snowflakes } from 'magic-snowflakes';

const defaultConfig = {
  color: 'white',
  count: 50,
  minSize: 8,
  maxSize: 18,
  rotation: true,
  speed: 1.0,
  wind: true
} as ISnowFlakeEffectComponentConfiguration;

const defaultStyle = {
} as CSSProperties;


export interface ISnowFlakeEffectComponentProps {
  pageComponent: PageComponent,
}

const SnowFlakeEffectComponent = (props: ISnowFlakeEffectComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = () => {
      // Draw here
      Snowflakes(context.config);
    };

    draw();
  }, [context.config, context.style, context.selector]);

  return renderComponentDiv(context);
};

export default SnowFlakeEffectComponent;
