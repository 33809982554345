// Npm
import React from 'react';

// Local
import { Page } from '../graphql/types';
import DeviceComponent from './DeviceComponent';
import { RenderContext } from '../services/RenderMachine';

export interface IPageProps {
  page: Page,
  renderContext: RenderContext,
  connectionSend: any
}

const page = (props: IPageProps) => {
  const {connectionSend} = props;

  var entitiesList = props.page.components!.map((c,i) => {
    return <DeviceComponent key={i} pageComponent={c!} renderContext={props.renderContext} connectionSend={connectionSend}/>
  });

  return <>
    { entitiesList }
    </>;
};

export default page;
