// NPM
import React from 'react';
import { Interpreter, State } from 'xstate';
import { useService } from '@xstate/react';
import { CommandBar, Panel, PanelType } from 'office-ui-fabric-react';

// Local
import {
  RootDeviceContext,
  RootDeviceEvent,
  RootDeviceMachineSchema,
} from '../../services/DeviceRootMachine';

export interface IDebugViewProps {
  current: State<RootDeviceContext, RootDeviceEvent, RootDeviceMachineSchema>,
  send: Interpreter<RootDeviceContext, any, RootDeviceEvent>['send']
  service: any;
}

export interface IDebugViewMachineProps {
  service: any;
}

export const DebugViewMachine = (props: IDebugViewMachineProps) => {
  const [current] = useService(props.service);

  return (<>
    <h2>{props.service.id}</h2>

    <h3>State</h3>
    <pre>{JSON.stringify(current.value)}</pre>

    <h3>Context</h3>
    <pre>{JSON.stringify(current.context, null, 2)}</pre>
  </>);
};

// Render device machine state
const DebugPanel = (props: IDebugViewProps) => {
  const { current, service } = props;
  const configService = service.children.get('configurator');

  const configuredService = service.children.get('configuredMachine');
  const renderService = configuredService ? configuredService.children.get('renderer') : undefined;
  const connectionService =  configuredService ? configuredService.children.get('connectionHandler') : undefined;

  const commandBarItems = () => {
    return [
      {
        key: 'reset',
        name: 'Reset',
        iconProps: {
          iconName: 'Delete',
        },
        onClick: () => {
          configuredService.send('CLEAR_CONFIGURATION');
        },
      },

    ];
  };

  const DebugViewCommandBar = () => <CommandBar items={commandBarItems()}/>;

  return (
    <Panel
      isOpen={current.context.showDebugPanel}
      type={PanelType.medium}
      headerText="Debug panel"
      onRenderNavigation={DebugViewCommandBar}
    >
      <DebugViewMachine service={service}/>

      {configService &&
       <DebugViewMachine service={configService}/>
      }

      {configuredService &&
      <DebugViewMachine service={configuredService}/>
      }

      {connectionService &&
        <DebugViewMachine service={connectionService}/>
      }

      {renderService &&
        <DebugViewMachine service={renderService}/>
      }
    </Panel>
  );
};

export default DebugPanel;
