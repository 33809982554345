// NPM

// Local
import { graphQlClient } from '../api/api';
import {
  GetDeviceContextDocument,
  GetDeviceContextQuery,
  GetDeviceContextQueryVariables,
} from '../graphql/types';

/////////////////////////////////////////////
// ACTIONS
/////////////////////////////////////////////


/////////////////////////////////////////////
// SERVICES
/////////////////////////////////////////////
export const fetchDeviceContext = (deviceId: string) =>
  graphQlClient.query<GetDeviceContextQuery, GetDeviceContextQueryVariables>({
    query: GetDeviceContextDocument,
    variables: {
        id: deviceId,
    },
  });
