// Npm
import React, { useEffect, useRef, useState } from 'react';
import { Interpreter, State } from 'xstate';
import * as THREE from 'three'
import Waves from 'vanta/dist/vanta.waves.min'
import backgroundImage from '../assets/img/config-background.png';

// Local
import LogoWithSubtitle from '../components/builtin/LogoWithSubtitle';
import LinkCodeLabel from '../components/builtin/LinkCodeLabel';
import { RootDeviceContext, RootDeviceEvent, RootDeviceMachineSchema } from '../services/DeviceRootMachine';
import QRCode from 'qrcode.react';
import getOurAppImage from '../assets/img/get-our-app.svg';
import appStore from '../assets/img/apple-app-store-badge.svg';
import playStore from '../assets/img/google-play-store-badge.svg';

export interface IRenderConfigurationProps {
  current: State<RootDeviceContext, RootDeviceEvent, RootDeviceMachineSchema>,
  send: Interpreter<RootDeviceContext, any, RootDeviceEvent>['send']
  service: any;
}

const RenderConfiguration = (props: IRenderConfigurationProps) => {
  const { current, service } = props;
  const configService = service.children.get('configurator');
  const [vantaEffect, setVantaEffect] = useState();
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(Waves({
        el: myRef.current,
        //color: '#273238',
        THREE: THREE
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect]);

  const status = current.value === 'configuring'
    ? 'Please scan the code using our app on your phone'
    : current.value.toString();

  return <div>
    <div ref={myRef} style={{width: '100vw', height: '100vh', backgroundColor: '#0981c4', backgroundImage: backgroundImage}} />
    <div>
      <LogoWithSubtitle subtitle={status}>
        {configService &&
        <LinkCodeLabel service={configService}/>
        }

      </LogoWithSubtitle>

      <div className="app-qr">
        <img src={getOurAppImage} alt='Get our app!' style={{ position: 'relative', left: '-70%', top: 10 }} />
        <QRCode
          value={`${window.API_BASE_URL}redirect/mobileapp`}
          size={'6vw'}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          includeMargin={true}
          renderAs={'svg'}
          style={{ display:'block', margin:'auto'}}
        />
        <div>&nbsp;</div>
        <span>
          <a href="https://apps.apple.com/app/id1496623410"><img src={appStore} alt='Apple app store' style={{float: 'right', width: '6vw' }} /></a>
          &nbsp;
          <a href="https://play.google.com/store/apps/details?id=org.rduce.pixon.app&hl=en"><img src={playStore} alt='Google play store'  style={{float: 'left', width: '6vw' }} /></a>
        </span>
      </div>
    </div>
  </div>;
};

export default RenderConfiguration;
