// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

// Defaults
const defaultConfig = {

};

const defaultStyle = {
} as CSSProperties;

// Component
export interface IDefaultComponentProps {
  pageComponent: PageComponent,
}

const DefaultComponent = (props: IDefaultComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = () => {
      if (!context.config || !context.selector) return;

      d3.select(context.selector)
        .append("h1")
        .text(pageComponent.component!.name);
    };

    draw();
  }, [pageComponent.component, context.config, context.selector]);

  return renderComponentDiv(context);
};

export default DefaultComponent;
