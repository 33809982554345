// Npm
import React from 'react';

// Local
import { RenderContext } from '../services/RenderMachine';
import LogoWithSubtitle from '../components/builtin/LogoWithSubtitle';
import Presentation from './Presentation';

export interface IRenderContextProps {
  renderContext: RenderContext,
  connectionSend: any
}

const RenderMain = (props: IRenderContextProps) => {
  const { renderContext, connectionSend } = props;
  const deviceContext = renderContext.context!;
  const currentPresentation = deviceContext.controller!.presentations!
    .find(v => v!.id === deviceContext.initialPresentation)!;

  return deviceContext.validLicense
    ? <Presentation presentation={currentPresentation} renderContext={renderContext} connectionSend={connectionSend}/>
    : <LogoWithSubtitle subtitle='Invalid license, please contact your Pixon administrator'/>;
};

export default RenderMain;
