// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent } from '../graphql/types';
import useNameOfDayDataSource from '../datasources/NameOfDayDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';
import { CSSPropertiesToString, loadComponent } from '../api/util';
import renderTemplate from '../api/renderTemplate';
import { DisplayType } from 'pixon-component-core';

// Defaults
const defaultConfig = {
};

const defaultStyle = {

} as CSSProperties;

// Component
export interface ITextNameOfDayComponentProps {
  pageComponent: PageComponent,
}

const TextNameOfDayComponent = (props: ITextNameOfDayComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useNameOfDayDataSource(pageComponent.component!.dataSource);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = async () => {
      if (!data  || !context.selector) return;
      if (context.template)
      {
        const output = await renderTemplate(context.template, data, { timeFormat: context.config.dateFormatString });

        d3.select(context.selector)
          .html(output)
          .attr("style", CSSPropertiesToString(context.style));

      } else {
        switch (context.config.displayType) {
          case DisplayType.Text:
            d3.select(context.selector)
              .append('div')
              .selectAll('span')
              .data(data.names)
              .enter()
              .append("span")
              .text((d:any,i)  =>
                i !== data.names.length-1
                  ? d + ', '
                  : d
              );
            break;
          case DisplayType.List:
          default:
            d3.select(context.selector)
              .append('ul')
              .selectAll('li')
              .data(data.names)
              .enter()
              .append("li")
              .text((d:any) => {
                return d;
              });
            break;
        }
      }
    };

    draw();
  }, [data, context.selector,context.config.displayType,context.template,context.style, context.config.dateFormatString]);

  return renderComponentDiv(context);
};

export default TextNameOfDayComponent;
