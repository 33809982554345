// NPM
import { useEffect, useState } from 'react';
import _ from "lodash";
import { IWeatherDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { http, isEmpty, loadDataSourceConfig } from '../api/util';

const moment = require('moment');
moment.locale('sv');

// Defaults
const defaultConfig = {
  refreshIntervalInMinutes: 30,
  forecastLocationLongitude: "12.71498",
  forecastLocationLatitude: "56.06314",
  locationString: "Vasatorpsvägen 1, 254 57, Helsingborg"
} as IWeatherDataSourceConfiguration;

// Component
export interface IDayForecast {
  icon: string,
  date: Date,
  tempHigh: number,
  tempLow: number
}

export interface ISmhiWeatherForecastData {
  forecasts: IDayForecast[]
}

const mapForecast = (data: any): IDayForecast[] => {
  const byDay = _.groupBy(data.timeSeries, (o:any) => moment.parseZone(o.validTime).format('YYYY-MM-DD'));

  return _.map(byDay, (o: any) =>
  {
    // See: https://opendata.smhi.se/apidocs/metfcst/parameters.html
    const dailyParameters = _.flatten(_.map(o, (d: any) => d.parameters));

    // Temperatures
    const tempParameters = _.filter(dailyParameters, (p:any) => p.name === 't');
    const tempValues = _.flatten(_.map(tempParameters, (t:any) => t.values));

    // Symbol from middle of day
    const symbolParameters = _.filter(dailyParameters, (p:any) => p.name === 'Wsymb2');
    const symbolValues = _.flatten(_.map(symbolParameters, (t:any) => t.values));

    // Smhi uses the symbol from 14:00 to indicate the daily forecast.
    let symbolIndex = _.findIndex(o, (d: any) => moment(d.validTime).locale('sv').hour() === 14 );

    // If thats not found, use the first entry as symbol (current day if past 14)
    if (symbolIndex < 0)
    {
      symbolIndex = 0;
    }
    const symbol = symbolValues[symbolIndex];

    const day:IDayForecast = {
      icon: symbol,
      date: _.minBy(o, (d: any) => d.validTime).validTime,
      tempHigh: _.max(tempValues),
      tempLow: _.min(tempValues)
    };

    return day;
  });
};

const refresh = (config: any, url: string, setData: (data: ISmhiWeatherForecastData) => void )=> {
  if (!config || isEmpty(url))
    return;

  const fetchData = async () => {
    return await http(url);
  };

  fetchData().then(result => {
    if (!result)
      return;

    const processed = mapForecast(result);

    setData({
      forecasts: processed
    });
  });
};

export default function useSmhiWeatherDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<ISmhiWeatherForecastData>();

  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));
  const MINIMUM_INTERVAL_IN_MINUTES = 5;
  const getUrl = (c: IWeatherDataSourceConfiguration) =>
    `https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/${c.forecastLocationLongitude}/lat/${c.forecastLocationLatitude}/data.json`;

  // Fetch data periodically
  useEffect(() => {
    const interval = setInterval(() => {
        refresh(config, getUrl(config), setData);
      },
      1000*60*Math.max(config.refreshIntervalInMinutes, MINIMUM_INTERVAL_IN_MINUTES)
    );
    return () => clearInterval(interval);
  }, [config, config.refreshIntervalInMinutes]);

  // Fetch on initial load
  useEffect(() => {
    refresh(config, getUrl(config), setData);
  }, [config]);

  return [data];
}
