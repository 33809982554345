// Npm
import { CSSProperties, useEffect, useState } from 'react';
// Local
import { PageComponent } from '../graphql/types';
import { CSSPropertiesToString, loadComponent } from '../api/util';
import { IDexcomComponentConfiguration } from 'pixon-component-core';
import renderTemplate from '../api/renderTemplate';
import * as d3 from 'd3';
import useDexcomDataSource from '../datasources/DexcomDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';



const defaultConfig = {
 showGraph: true
} as IDexcomComponentConfiguration;



const defaultStyle = {
} as CSSProperties;

export interface IDexcomComponentProps {
  pageComponent: PageComponent,
}

const DexcomComponent = (props: IDexcomComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useDexcomDataSource(pageComponent.component!.dataSource);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    // Draw data on top
    if (data){
      d3.select(context.selector)
        .html('NO DATA!')
        .attr("style", CSSPropertiesToString(context.style));
    }

    const draw = async () => {
      if (context.template) {
        const output = await renderTemplate(context.template, data, { timeFormat: context.config.dateFormatString });
        d3.select(context.selector)
          .html(output)
          .attr("style", CSSPropertiesToString(context.style));
      }
    };

    draw();
  }, [data, context.config, context.style, context.selector, context.template]);

  return renderComponentDiv(context);
};

export default DexcomComponent;
