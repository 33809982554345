// NPM
import React, { CSSProperties, useState } from 'react';
import { IDeviceVoteCategoryButtonConfiguration } from 'pixon-component-core';

// Local
import { DeviceMessageEnvelope, PageComponent } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';
import { loadComponent, readIotConfig } from '../api/util';
import { Stack, IStackTokens, Image } from 'office-ui-fabric-react';
import useDeviceEventDataSource from '../datasources/DeviceEventDataSource';
import { renderTemplateString } from '../api/renderTemplate';
import * as d3 from 'd3';

// Defaults
interface ICategoryInformation {
  category: string
  text: string
}

const positiveVotesConfig:ICategoryInformation[] = [
  {
    category: 'colored-glass',
    text: 'Färgat glas',
  },
  {
    category: 'newspapers',
    text: 'Tidningar'
  },
  {
    category: 'food',
    text: 'Matrester'
  },
  {
    category: 'plastic',
    text: 'Plast'
  },
  {
    category: 'metal',
    text: 'Metall'
  },
  {
    category: 'uncolored-glass',
    text: 'Ofärgat glas'
  },
  {
    category: 'paper',
    text: 'Papp och papper'
  },
  {
    category: 'dangerous-goods',
    text: 'Farligt gods'
  },
  {
    category: 'other',
    text: 'Restavfall'
  }
];

const negativeVotesConfig:ICategoryInformation[] = [
  {
    category: 'colored-glass',
    text: 'Ej glasförpackning'
  },
  {
    category: 'newspapers',
    text: 'Ej tidningar'
  },
  {
    category: 'food',
    text: 'Ej matrest'
  },
  {
    category: 'plastic',
    text: 'Ej plast'
  },
  {
    category: 'metal',
    text: 'Ej metall'
  },
  {
    category: 'uncolored-glass',
    text: 'Ej ofärgat glas'
  },
  {
    category: 'paper',
    text: 'Ej papp eller papper'
  },
  {
    category: 'dangerous-goods',
    text: 'Ej farligt gods'
  },
  {
    category: 'other',
    text: 'Ej restavfall'
  }
];

const defaultConfig = {
  vote: 1
} as IDeviceVoteCategoryButtonConfiguration;

const defaultStyle = {
  zIndex: 99999999
} as CSSProperties;

// Component
export interface IDeviceSendMessageButtonComponentProps {
  pageComponent: PageComponent,
  renderContext: RenderContext,
  connectionSend: any
}

// Render device machine state
const DeviceVoteCategoryButtonComponent = (props: IDeviceSendMessageButtonComponentProps) => {
  const {
    pageComponent,
    connectionSend,
    renderContext
  } = props;

  const component = pageComponent.component!;
  const cfg = readIotConfig();
  const dataSource = component.dataSource;
  const [data] = useDeviceEventDataSource(renderContext, component.dataSource);
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const config = context.config;

  if (!data || !data.payload)
    return null;

  const lastPayload = JSON.parse(data.payload);

  if (!lastPayload || !config)
    return null;

  const wrapStackTokens: IStackTokens = { childrenGap: 30 };
  const isNegative = config.vote < 0;

  if (isNegative && lastPayload.category === 'unknown')
    return null;

  if (!isNegative && lastPayload.category !== 'unknown')
    return null;

  const onClick = (category: string) => {
    const cmd = {
      from: {
        componentId: component.id,
        deviceId: cfg.deviceId,
        iotId: cfg.deviceIotId
      },
      to: {
        componentId: dataSource ?  dataSource.id! : '',
        iotId: ''
      },
      message: {
        name: 'DeviceGarbageProductCategoryVote',
        productIdentifier: lastPayload.identifier,
        categoryName: category,
        vote: context.config.vote
      }
    } as DeviceMessageEnvelope;

    connectionSend('SEND_MESSAGE', { data: cmd } );

    const showSelector = renderTemplateString(context.config.onVoteShowSelector ?? '', { category: category });
    const hideSelector = renderTemplateString(context.config.onVoteHideSelector ?? '', { category: category });

    console.log('hiding', hideSelector);
    console.log('showing', showSelector);

    d3.selectAll(hideSelector)
      .style('visibility', 'hidden');
    d3.selectAll(showSelector)
      .style('visibility', 'visible');
  };


  const categoryButtons = (isNegative ? negativeVotesConfig : positiveVotesConfig)
    .filter((c:ICategoryInformation) => lastPayload.category === 'unknown'  || c.category === lastPayload.category)
    .map((c:ICategoryInformation) =>
      <Image
        key={c.category}
        className={'button'}
        src={`https://pixonprodst0.blob.core.windows.net/public/buttons/${isNegative ? 'button-incorrect' : 'button-' + c.category}.svg`}
        onTouchEnd={() => onClick(c.category)}
        onClick={() => onClick(c.category)}
        style={{
          width: '518px',
          height: '126px'
        }}>

      </Image>
  );


  return <div
    id={context.id}
    style={context.style}
    className={`horizontal${context.pageComponent.horizontalAlign!} vertical${context.pageComponent.verticalAlign!} ${context.pageComponent.component!.classNames}`}
  >
    <Stack
      horizontal
      tokens={wrapStackTokens}
      wrap={true}
      horizontalAlign={'center'}
      verticalAlign={'center'}
      styles={{
        root: {
          marginTop: 'auto',
          marginBottom:'auto'
        }
      }}>
      { categoryButtons }
    </Stack>
  </div>;

};

export default DeviceVoteCategoryButtonComponent;
