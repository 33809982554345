// Npm
import React, { ReactNode } from 'react';
// Local
import { ComponentTypeEnum, PageComponent } from '../graphql/types';
import BackgroundImageComponent from '../components/BackgroundImageComponent';
import TextTableComponent from '../components/TextTableComponent';
import TextClockComponent from '../components/TextClockComponent';
import TextParkgardenActivitiesComponent from '../components/TextParkgardenActivitiesComponent';
import TextParkgardenLunchComponent from '../components/TextParkgardenLunchComponent';
import SmhiWeatherComponent from '../components/SmhiWeatherComponent';
import DeviceMessageComponent from '../components/DeviceMessageComponent';
import { RenderContext } from '../services/RenderMachine';
import DeviceSendMessageButtonComponent from '../components/DeviceSendMessageButtonComponent';
import DefaultComponent from '../components/DefaultComponent';
import RssComponent from '../components/RssComponent';
import CalendarComponent from '../components/CalendarComponent';
import TransitStopComponent from '../components/TransitStopComponent';
import TextNameOfDayComponent from '../components/TextNameOfDayComponent';
import { IComponentRenderContext } from '../api/util';
import TextLabelComponent from '../components/TextLabelComponent';
import DeviceEventComponent from '../components/DeviceEventComponent';
import ImageComponent from '../components/ImageComponent';
import DeviceVoteCategoryButtonComponent from '../components/DeviceVoteCategoryButtonComponent';
import SnowFlakeEffectComponent from '../components/SnowFlakeEffectComponent';
import IframeComponent from '../components/IframeComponent';
import CameraBarcodeReaderComponent from '../components/CameraBarcodeReaderComponent';
import ImageCarouselComponent from '../components/ImageCarouselComponent';
import DexcomComponent from '../components/DexcomComponent';
import KeyboardBarcodeReaderComponent from '../components/KeyboardBarcodeReaderComponent';

export interface IDeviceComponentProps {
  pageComponent: PageComponent,
  renderContext: RenderContext,
  connectionSend: any
}

export const renderComponentDiv = (context:IComponentRenderContext) =>
  <div
    id={context.id}
    style={context.style}
    className={`horizontal${context.pageComponent.horizontalAlign!} vertical${context.pageComponent.verticalAlign!} ${context.pageComponent.component!.classNames}`}
  />;

export interface IComponentDivProps {
  context: IComponentRenderContext,
  children: ReactNode
}

export const ComponentDiv = (props: IComponentDivProps) => {
  const {context, children} = props;
  return <div
    id={context.id}
    style={context.style}
    className={`horizontal${context.pageComponent.horizontalAlign!} vertical${context.pageComponent.verticalAlign!} ${context.pageComponent.component!.classNames}`}
  >
    {children}
  </div>
};

const deviceComponent = (props: IDeviceComponentProps) => {
  const {pageComponent, connectionSend} = props;

  switch (pageComponent.component!.componentType) {
    case ComponentTypeEnum.BackgroundImage:
      return <BackgroundImageComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TextTable:
      return <TextTableComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TextClock:
      return <TextClockComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.ScraperParkgardenActivities:
      return <TextParkgardenActivitiesComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.ScraperParkgardenLunch:
      return <TextParkgardenLunchComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.WeatherSmhi:
      return <SmhiWeatherComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.DeviceEventAction:
      return <DeviceEventComponent pageComponent={pageComponent} renderContext={props.renderContext} />;
    case ComponentTypeEnum.DeviceMessage:
      return <DeviceMessageComponent pageComponent={pageComponent} renderContext={props.renderContext} />;
    case ComponentTypeEnum.DeviceMessageButton:
      return <DeviceSendMessageButtonComponent pageComponent={pageComponent} renderContext={props.renderContext} connectionSend={connectionSend} />;
    case ComponentTypeEnum.RssFeed:
      return <RssComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.Calendar:
      return <CalendarComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TransitTimetable:
      return <TransitStopComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TextNameOfDay:
      return <TextNameOfDayComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TextLabel:
      return <TextLabelComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.Image:
      return <ImageComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.DeviceGarbageCategoryVoteButton:
      return <DeviceVoteCategoryButtonComponent pageComponent={pageComponent} renderContext={props.renderContext} connectionSend={connectionSend} />;
    case ComponentTypeEnum.FullscreenSnowflakeEffect:
      return <SnowFlakeEffectComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.Iframe:
      return <IframeComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.TextBarcodeReader:
      return <CameraBarcodeReaderComponent pageComponent={pageComponent} connectionSend={connectionSend} />;
    case ComponentTypeEnum.TextKeyboardBarcodeReader:
      return <KeyboardBarcodeReaderComponent pageComponent={pageComponent} connectionSend={connectionSend} />;
    case ComponentTypeEnum.ImageCarousel:
      return <ImageCarouselComponent pageComponent={pageComponent} />;
    case ComponentTypeEnum.Dexcom:
      return <DexcomComponent pageComponent={pageComponent} />;
    default:
      return <DefaultComponent pageComponent={pageComponent} />;
  }

};

export default deviceComponent;
