// NPM
import { useState } from 'react';
import useInterval from '@use-it/interval';
import { Moment } from 'moment-timezone/moment-timezone';
import moment from 'moment-timezone';
import { ITransitStopDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { http, isEmpty, loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  apiKey: '',
  stopId: '',
  refreshIntervalInMinutes: 5,
  maxNumberOfItemsToFetch: 8
} as ITransitStopDataSourceConfiguration;

// Component
export interface ITransitStop {
  departureTime: Moment,
  stop: string,
  name: string,
  direction: string,
  transportNumber: string,
  transportCategory: string,
  type: string
}

export interface ITransitStopData {
  items: ITransitStop[]
}

export default function useTransitStopDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<ITransitStopData>();

  // Initial load after 5 second
  const [delay, setDelay] = useState(5000);
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  useInterval(async () => {
    if (isEmpty(config.stopId) || isEmpty(config.apiKey)) {
      return;
    }

    const dataUrl = `https://api.resrobot.se/v2/departureBoard?key=${config.apiKey}&id=${config.stopId}&maxJourneys=${config.maxNumberOfItemsToFetch}&format=json&passlist=0'`;
    const journeys = await http(dataUrl);

    if (!journeys || !journeys.Departure)
      return;

    setData({
      items: journeys.Departure.map((d:any) => {
        return {
          departureTime: moment(`${d.date} ${d.time}`),
          name: d.name,
          stop: d.stop,
          direction: d.direction,
          transportNumber: d.transportNumber,
          transportCategory: d.transportCategory,
          type: d.type
        }
      }),
    });

    // Refetch at configured interval, never quicker than 5 minutes
    setDelay(1000 * 60 * Math.max(5, config.refreshIntervalInMinutes));
  }, delay);

  return [data];
}
