// NPM
import { useState } from 'react';
import useInterval from "@use-it/interval";
import ical from 'ical';
import { Moment } from 'moment-timezone/moment-timezone';
import { ICalendarDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { isEmpty, loadDataSourceConfig } from '../api/util';
import moment from 'moment-timezone';

// Defaults
const defaultConfig = {
  icalUrl: '',
  refreshIntervalInMinutes: 5
} as ICalendarDataSourceConfiguration;

// Component
export interface CalendarItem {
  startDate?: string,
  endDate?: string,
  startTimestamp?: Moment,
  endTimestamp?: Moment,
  description?: string,
  summary?: string,
  location?: string
  color?: string
}

export interface iCalData {
  items: CalendarItem[]
}

export default function useCalendarDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<iCalData>({ items: []});

  // Initial load after 5 second
  const [delay, setDelay] = useState(5000);
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  useInterval(() => {
    if (isEmpty(config.icalUrl))
      return;

    ical.fromURL(config.icalUrl, {}, function (err: any, icalData:any) {
      if (err || !icalData) return;

      let items:CalendarItem[] = [];

      for (let k in icalData) {
        if (icalData.hasOwnProperty(k)) {
          let ev = icalData[k];

          if (icalData[k].type === 'VEVENT') {
            items.push({
              startDate: ev.start? moment(ev.start).format(moment.HTML5_FMT.DATE) : undefined,
              endDate: ev.start? moment(ev.end).format(moment.HTML5_FMT.DATE) : undefined,
              startTimestamp:  ev.start ? ev.start.toISOString():undefined,
              endTimestamp: ev.end ? ev.end.toISOString():undefined,
              summary: ev.summary,
              location: ev.location && ev.location.trim(),
              description: ev.description,
              color: ev.color ? ev.color : '#4bdf6a'
            })
          }
        }
      }

      const filtered = items
        // Only include items that have not passed
        .filter((a: CalendarItem) => !a.endTimestamp || moment(a.endTimestamp).isAfter())
        // Sort by startTime
        .sort((a: CalendarItem, b: CalendarItem) => moment(a.startTimestamp).diff(moment(b.startTimestamp)));

      setData({
        items: filtered
      });

      // Refetch at configured interval, never quicker than 5 minutes
      setDelay(1000*60*Math.max(5, config.refreshIntervalInMinutes));
    });
  }, delay);

  return [data];
}
