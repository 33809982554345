import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
  private appInsights: any;
  reactPlugin: ReactPlugin;

  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig: any) {
    const INSTRUMENTATION_KEY = window.APPLICATION_INSIGHTS_KEY;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig
        }
      }
    });

    this.appInsights.loadAppInsights();
    this.initializeDeviceId();
  }

  private initializeDeviceId() {
    const existingCfgStr = localStorage.getItem('iotConfig');
    if (existingCfgStr)
    {
      const cfg = JSON.parse(existingCfgStr);
      this.appInsights.setAuthenticatedUserContext(cfg.deviceIotId);
    }
  }

  logEvent(name: string, customProperties?: {[key: string]: any}) {
    this.appInsights.trackEvent({name: name}, customProperties);
  }

  trackTrace(name: string, customProperties?: {[key: string]: any}) {
    this.appInsights.trackTrace({name: name}, customProperties);
  }

  trackException(exception: Error, customProperties?: {[key: string]: any}) {
    this.appInsights.trackException(exception, customProperties);
  }
}

export let ai = new TelemetryService();
