// NPM
import { useEffect, useState } from 'react';

// Local
import { DataSource } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';

// Defaults

// Component
export interface IDeviceMessageData {
  message: string,
  timestamp: Date
}

export default function useDeviceMessageDataSource(renderContext: RenderContext, dataSource?: DataSource | null) {
  const [data, setData] = useState<IDeviceMessageData>();

  useEffect(() => {
    if (!renderContext || !dataSource)
      return;

    const messageEvent = renderContext ? renderContext.componentMessage : undefined;

    if (!messageEvent || messageEvent.target !== dataSource.id)
      return;

    setData({
      message: messageEvent.message,
      timestamp: new Date(),
    });
  }, [renderContext, dataSource]);

  return [data];
}
