// Npm
import React from 'react';

// Local
import { Presentation } from '../graphql/types';
import Page from './Page';
import { RenderContext } from '../services/RenderMachine';

export interface IPresentationProps {
  presentation: Presentation,
  renderContext: RenderContext,
  connectionSend: any
}

const presentation = (props: IPresentationProps) => {
  const {renderContext, connectionSend} = props;
  // Currently just take the first page and render
  const currentPage = props.presentation.pages![0]!;

  return <>{ <Page page={currentPage} renderContext={renderContext} connectionSend={connectionSend}/> }</>
};

export default presentation;
