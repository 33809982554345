// NPM
import { assign, EventObject, Machine } from 'xstate';

// Local
import { isEmpty, readIotConfig } from '../api/util';
import { createIotConfig, fetchIotConfig, fetchLinkCode, getDeviceTempId } from './IotConfigurationService';

/////////////////////////////////////////////
// CONTRACTS
/////////////////////////////////////////////

// State machine
export interface IotConfigurationMachineSchema {
  states: {
    uninitialized: {};
    fetchLinkCode: {};
    fetchConfig: {};
    iotConfigured: {};
    waitForDeviceLink: {};
    linkCodeFailure: {};
    configFailure: {};
    configReceived: {};
  };
}

// The events that the machine handles
export type EventId =
  'GENERATE_PERSISTENT_DEVICE_TEMP_ID'
  | 'FETCH_LINK_CODE'
  | 'LINK_CODE_RECEIVED'
  | 'IOT_CONFIG_RECEIVED';

export interface IotConfigurationEvent extends EventObject {
  type: EventId;
  data: any;
}

// The context (extended state) of the machine
export interface IotConfigurationContext {
  deviceTempId: string;
  linkCode: string;
  config: any | undefined;
  error: any | undefined;
  linkComplete: boolean;
}

// Other models
export interface IoTConfig {
  hubHostname: string;
  organizationId: string;
  deviceId: string;
  deviceIotId: string;
  key: string;
  topic: string;
  publishTopic: string;
}

// Machine implementation
const iotConfigurationMachine = Machine<IotConfigurationContext, IotConfigurationMachineSchema, IotConfigurationEvent>({
    key: 'iotConfigurationMachine',
    initial: 'uninitialized',
    strict: true,
    context: {
      deviceTempId: '',
      linkCode: '',
      config: undefined,
      error: undefined,
      linkComplete: false
    },
    states: {
      uninitialized: {
        on: {
          '': [
            // if we have a temp id, move on to retrieving a link code
            { target: 'fetchLinkCode', cond: 'haveDeviceTempId' },
            // otherwise load or create a deterministic temp id
            { target: 'uninitialized', cond: 'doesNotHaveDeviceTempId', actions: 'getDeviceTempId' },
          ],
        },
      },
      // Retrieve a link code to show on display
      fetchLinkCode: {
        invoke: {
          id: 'getLinkCode',
          src: context => fetchLinkCode(context.deviceTempId),
          onDone: { target: 'fetchConfig', actions: 'assignLinkCode' },
          onError: { target: 'linkCodeFailure', actions: 'assignError' }
        },
      },

      // Retry indefinitely after some time if we failed to fetch the link code
      linkCodeFailure: {
        after: {
          LINK_CODE_FAILURE_RETRY_INTERVAL: 'fetchLinkCode',
        },
      },

      fetchConfig: {
        invoke: {
          id: 'getConfig',
          src: context => fetchIotConfig(context.deviceTempId, context.linkCode),
          onDone: { target: 'configReceived', actions: 'assignIotConfig' },
          onError: { target: 'configFailure', actions: 'assignError' }
        }
      },

      configReceived: {
        on: {
          '': [
            // if link is not complete yet, keep retrying. Waiting for someone
            // to link the device to the organization
            { target: 'waitForDeviceLink', cond: 'linkNotComplete' },
            { target: 'iotConfigured', cond: 'linkComplete', actions: 'persistIotConfig' }
          ],
        },
      },

      // Retry indefinitely after some time if we failed to fetch the link code
      waitForDeviceLink: {
        after: {
          IOT_CODE_FAILURE_RETRY_INTERVAL: 'fetchConfig',
        },
      },

      // Retry indefinitely after some time if we failed to fetch the link code
      configFailure: {
        after: {
          IOT_CODE_FAILURE_RETRY_INTERVAL: 'fetchConfig',
        },
      },

      iotConfigured: {
        // mark as final state so that hierarchical machines
        // understand when config is done
        type: 'final',
        // Return configuration to parent machine
        data: {
          config: (context: IotConfigurationContext, event:IotConfigurationEvent) => context.config
        }
      }

    },
  },
  {
    delays: {
      LINK_CODE_FAILURE_RETRY_INTERVAL: 10000, // static value
      IOT_CODE_FAILURE_RETRY_INTERVAL: 10000, // static value
    },

    actions: {
      getDeviceTempId: assign<IotConfigurationContext>({
        deviceTempId: getDeviceTempId()
      }),
      assignLinkCode: assign<IotConfigurationContext, IotConfigurationEvent>({
        linkCode: (context, event) =>
          event.data.data.createDeviceLinkTicket.ticket.code
      }),
      assignIotConfig: assign<IotConfigurationContext, IotConfigurationEvent>({
        config: (context: IotConfigurationContext, event:IotConfigurationEvent)  => createIotConfig(event),
        linkComplete: (context, event) =>  event.data.data.completeDeviceLink.deviceCredentials.linkComplete
      }),
      assignError: assign<IotConfigurationContext>({
        error: (context: IotConfigurationContext, event:IotConfigurationEvent) => event.data
      }),
      persistIotConfig:  (context) => {
        // Persist the configuration
        // Question is: should we store this as a cookie instead (from a security perspective)?
        // https://scotch.io/@PratyushB/local-storage-vs-session-storage-vs-cookie
        localStorage.setItem('iotConfig', JSON.stringify(context.config));
      }
    },

    // Guard methods to prevent faulty transitions
    guards: {
      haveDeviceTempId: (context) => !isEmpty(context.deviceTempId),
      doesNotHaveDeviceTempId: (context) => isEmpty(context.deviceTempId),

      hasLinkCode: (context) => !isEmpty(context.linkCode),

      haveIotConfig: (context) => context.config !== undefined,
      doesNotHaveIotConfig: (context) => context.config === undefined,

      linkNotComplete: (context) => context.config === undefined || !context.linkComplete,
      linkComplete: (context) => context.config !== undefined && context.linkComplete && readIotConfig !== undefined,
    },

  },
);

export default iotConfigurationMachine;
