import { IStackItemStyles, IStackTokens, Stack } from 'office-ui-fabric-react';
import React from 'react';
import { DeviceContext } from '../../graphql/types';

export interface IStatusBarProps {
  deviceContext: DeviceContext;
  connectionState: any;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const stackItemStyles: IStackItemStyles = {
  root: {
    //background: DefaultPalette.themePrimary,
    //color: DefaultPalette.white,
    padding: 5,
    margin: 5
  }
};

const StatusBar = (props: IStatusBarProps) => {
  const {deviceContext, connectionState} = props;

  const deviceName = (deviceContext && deviceContext.deviceName) ? deviceContext.deviceName : 'N/A';
  const organizationName = (deviceContext && deviceContext.organizationName) ? deviceContext.organizationName : 'N/A';
  const licenseMessage = deviceContext
    ? deviceContext.validLicense
      ? 'License is valid'
      : deviceContext.licenseErrorMessage
    : 'N/A';

  return <Stack horizontal horizontalAlign="space-between" id="statusBar">
    <Stack horizontal horizontalAlign="start" tokens={stackTokens} styles={stackItemStyles}>
        <p>{deviceName} @ {organizationName} | {licenseMessage}</p>
    </Stack>
    <Stack horizontal horizontalAlign="end" tokens={stackTokens} styles={stackItemStyles}>
        <p>{connectionState.value}</p>
    </Stack>
  </Stack>;
};

export default StatusBar;
