// NPM
import { useState } from 'react';
import Parser, { Item } from 'rss-parser';
import useInterval from '@use-it/interval';
import { IRssDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { isEmpty, loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  feedUrl: '',
  refreshIntervalInMinutes: 5
} as IRssDataSourceConfiguration;

// Component
export interface IRssData {
  items: Item[]
}

// Note: some RSS feeds can't be loaded in the browser due to CORS security.
// To get around this, you can use a proxy.
const CORS_PROXY = 'https://corsproxy.pixon.app/';

export default function useRssDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<IRssData>({ items: []});

  // Initial load after 5 second
  const [delay, setDelay] = useState(5000);
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  useInterval(() => {
    if (isEmpty(config.feedUrl))
      return;

    let parser = new Parser();
    parser.parseURL(CORS_PROXY + config.feedUrl, function(err, feed) {
      if (err || !feed || !feed.items) return;

      setData({
        items: feed.items
      });

      // Refetch at configured interval, never quicker than 5 minutes
      setDelay(1000*60*Math.max(5, config.refreshIntervalInMinutes));
    });
  }, delay);

  return [data];
}
