// Npm
import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import _uniqueId from 'lodash/uniqueId';

// Local
import { Asset, PageComponent } from '../graphql/types';
import { getAssetUrl } from '../api/util';

export interface IBackgroundImageComponentProps {
  pageComponent: PageComponent,
}

const BackgroundImageComponent = (props: IBackgroundImageComponentProps) => {
  const {pageComponent} = props;
  const component = pageComponent.component!;

  // id will be set once when the component initially renders, but never again
  // (unless you assigned and called the second argument of the tuple)
  const [id] = useState(_uniqueId('backgroundImageComponent-'));
  const MainSelector = '#' + id;

  // Pick first asset for now, but later on we could compose backgrounds from
  // more assets (such as different resolutions, videos, etc)
  const image = component.assets![0] as Asset;

  // Redraw background on image url change
  useEffect(() => {
    if (!image)
      return;

    const imageUrl = getAssetUrl(image);

    // Clear drawing
    d3.select(MainSelector + ' > *').remove();

    const draw = (url: string) => {
      const mainSvg = d3.select(MainSelector)
        .append('svg')
        .attr('class', 'fillParent');

      d3.xml(url)
        .then((documentFragment:any) => {
          const svgNode = documentFragment
            .getElementsByTagName("svg")[0];

          mainSvg.node()!.appendChild(svgNode);
        })
        .catch(e => console.log('could not load url', url, e));
    };

    // Redraw
    draw(imageUrl);
  }, [image, MainSelector]);



  return <div id={id} className='fillParent'/>
};

export default BackgroundImageComponent;
