import { assign, Machine, send, sendParent } from 'xstate';
import MQTT from 'mqtt';

import { IoTConfig } from './IotConfigurationMachine';
import { ai } from '../services/TelemetryService';
import { getSaSToken, readIotConfig, uploadScreenshot } from '../api/util';
import {
  DeviceMessage, RefreshContextCommand, RequestScreenShotCommand,
  SetLocalStorageValueCommand,
  ToggleDebugPanelCommand, ToggleStatusbarCommand,
} from '../graphql/types';

// The hierarchical (recursive) schema for the states
export interface IotConnectionMachineSchema {
  states: {
    uninitialized: {};
    connected: {};
    disconnected: {};
    error: {};
  };
}

export interface IotConnectionContext {
  iotConfig?: IoTConfig,
}

// The events that the machine handles
export type IotConnectionEvent =
  | { type: 'REFRESH_DEVICE_CONTEXT'; data: any; }
  | { type: 'TOGGLE_DEBUG_PANEL'; data: any; }
  | { type: 'TOGGLE_STATUSBAR'; data: any; }
  | { type: 'FORWARD_COMPONENT_MESSAGE'; data: any; }
  | { type: 'CONNECT'; data: any; }
  | { type: 'RECONNECT'; data: any; }
  | { type: 'CLOSE'; data: any; }
  | { type: 'MESSAGE_RECEIVED'; data: any; }
  | { type: 'SEND_MESSAGE'; data: any; }
  | { type: 'ERROR'; data: any; }
  | { type: 'DISCONNECT'; data: any; };


const iotConnectionMachine = Machine<IotConnectionContext, IotConnectionMachineSchema, IotConnectionEvent>({
  key: 'root',
  initial: 'uninitialized',
  context: {
    iotConfig: undefined
  },
  invoke: {
    id: 'socket',
    onError: 'error',
    src: (context, event) => (callback, onEvent) => {
      const cfg = readIotConfig();

      if (!cfg)
      {
        callback( {type: 'ERROR', data: 'missing config'});
        return;
      }

      const client = MQTT.connect({
        host: cfg.hubHostname,
        port: 443,
        path: '/$iothub/websocket?iothub-no-client-cert=true',
        defaultProtocol: 'wss',
        protocol: 'mqtts',
        protocolId: 'MQTT',
        protocolVersion: 4,
        clientId: cfg.deviceIotId,
        username: cfg.hubHostname + '/' +  cfg.deviceIotId + '/api-version=2016-11-14',
        password: getSaSToken(cfg.hubHostname, cfg.deviceIotId, cfg.key ),
        keepalive: 30000,

        // Receive messages that were sent while offlinie
        // https://docs.microsoft.com/en-us/azure/iot-hub/iot-hub-mqtt-support#receiving-cloud-to-device-messages
        // https://www.npmjs.com/package/mqtt#mqttclientstreambuilder-options
        clean: false

      });

      // Send events from parent machine to the iot hub
      onEvent((e:any) => {
        client.publish(cfg.publishTopic, JSON.stringify(e.data))
      });

      client.on('connect', (packet: any) => {
        ai.logEvent('CONNECT');
        // Subscribe to messages for this device
        client.subscribe(cfg.topic);

        // Subscribe to direct method invocations for this device
        client.subscribe('$iothub/methods/POST/#');

        callback( {type: 'CONNECT', data: packet});
      });

      client.on('reconnect', (packet: any) => {
        ai.logEvent('RECONNECT');
        callback({ type: 'RECONNECT', data: packet});
      });

      client.on('close', (packet: any) => {
        ai.logEvent('CLOSE');
        callback({ type: 'CLOSE', data: packet});
      });

      client.on('message', (topic: string, message: any, packet: any) => {
        const decodedMessage = new TextDecoder("utf-8").decode(message);
        ai.logEvent('MESSAGE_RECEIVED', {'message': decodedMessage});

        // Handle direct method calls
        // https://docs.microsoft.com/en-us/azure/iot-hub/iot-hub-devguide-direct-methods#handle-a-direct-method-on-a-device
        if (topic.indexOf('$iothub/methods/POST/') >= 0) {
          const regExp = /\$iothub\/methods\/POST\/(\w+)\/\?\$rid=(\w+)/g;
          const match = regExp.exec(topic);
          if (match)
          {
            const methodName = match[1];
            const requestId = match[2];
            const status = 200;

            switch (methodName) {
              case "ReloadCommand": {
                window.location.reload();
                break;
              }
              case "RefreshContextCommand": {
                callback({type: 'REFRESH_DEVICE_CONTEXT',  data: {} as RefreshContextCommand });
                break;
              }
              default: {
                console.log('Unexpected direct method call received', methodName);
                ai.trackException(new Error('Unexpected command received'), { method: methodName, payload: decodedMessage} );
              }
            }

            // Respond to the method invocation
            client.publish(`$iothub/methods/res/${status}/?$rid=${requestId}`, status.toString());
          }

          return;
        }

        // Handle device message
        try {
          const obj: DeviceMessage = JSON.parse(decodedMessage);
          switch (obj.name) {
            case "ReloadCommand": {
              window.location.reload();
              break;
            }
            case "UnlinkCommand": {
              localStorage.removeItem('iotConfig');
              window.location.reload();
              break;
            }
            case "ToggleDebugPanelCommand": {
              const cmd = obj as ToggleDebugPanelCommand;
              callback({ type: 'TOGGLE_DEBUG_PANEL', data: cmd });
              break;
            }
            case "ToggleStatusbarCommand": {
              const cmd = obj as ToggleStatusbarCommand;
              callback({type: 'TOGGLE_STATUSBAR',  data: cmd });
              break;
            }
            case "RefreshContextCommand": {
              const cmd = obj as RefreshContextCommand;
              callback({type: 'REFRESH_DEVICE_CONTEXT',  data: cmd });
              break;
            }
            case "SetPresentationCommand": {
              console.log('NO ACTION ON SETPRESENTATIONCOMMAND');
              break;
            }
            case "SetLocalStorageValueCommand": {
              const cmd = obj as SetLocalStorageValueCommand;
              localStorage.setItem(cmd.storageKey, cmd.storageValue);
              break;
            }
            case "RequestScreenShotCommand": {
              const cmd = obj as RequestScreenShotCommand;
              console.log('Screenshot requested');
              uploadScreenshot(cmd.imageSasUrl, cmd.thumbnailSasUrl);
              break;
            }
            default: {
              callback({ type: 'FORWARD_COMPONENT_MESSAGE', data: obj });
              //ai.trackException(new Error('Unexpected command received'), obj);
            }
          }
        } catch (e) {
          console.log('Could not parse message', e, decodedMessage);
          ai.trackException(new Error('Could not parse message'), {'decodedMessage': decodedMessage});
        }
      });

      client.on('error', (packet: any) => {
        ai.trackException(new Error('Mqtt Connection Error'));
        callback( {type: 'ERROR', data: packet});
      });

      // Return FUNCTION that perform cleanup
      return () => {
        client.end();
      };
    }
  },
  states: {
    // Start disconnected, and then exit on errors
    // letting the parent machine restart the connection
    uninitialized: {
      entry: [
        'readConfig'
      ],
      on: {
        ERROR: 'error',
        CLOSE: 'disconnected',
        DISCONNECT: 'disconnected',
        CONNECT: {
          target: 'connected',
          actions: 'forwardToParent'
        }
      }
    },


    connected: {
      on: {
        ERROR: 'error',
        CLOSE: 'disconnected',
        DISCONNECT: 'disconnected',
        TOGGLE_STATUSBAR: {
          actions: 'forwardToParent'
        },
        FORWARD_COMPONENT_MESSAGE: {
          actions: 'forwardToParent'
        },
        TOGGLE_DEBUG_PANEL: {
          actions: 'forwardToParent'
        },
        REFRESH_DEVICE_CONTEXT: {
          actions: 'forwardToParent'
        },
        SEND_MESSAGE: {
          actions: send((_, e) => e, { to: 'socket' })
        }
      }
    },

    // Disconnected, should retry
    disconnected: {
      type:'final',
      data: {
        isError: () => false
      }
    },

    // Error, do not retry
    error: {
      type:'final',
      // Return reason to parent machine
      data: {
        isError: () => true
      }
    }
  }
}, {
  actions: {
    forwardToParent: sendParent<IotConnectionContext, IotConnectionEvent>((ctx,e) => (e)),
    readConfig: assign<IotConnectionContext, IotConnectionEvent>({
      iotConfig: (context, event) => {
        return readIotConfig()
      }
    }),
  }
});

export default iotConnectionMachine;
