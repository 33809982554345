// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';
import useResidentRegistryDataSource from '../datasources/ResidentRegistryDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';
import { ITextTableConfiguration } from 'pixon-component-core';

// Defaults
const defaultConfig = {
  headers: false,
  columns:  ['name', 'apartment_number'],
} as ITextTableConfiguration;

const defaultStyle = {
  width: '400px',
} as CSSProperties;

// Component
export interface ITextTableComponentProps {
  pageComponent: PageComponent
}

const TextTableComponent = (props: ITextTableComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useResidentRegistryDataSource(pageComponent.component!.dataSource);

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = () => {
      // Draw data on top
      if (!data) return;

      const config = context.config;
      const table = d3.select(context.selector)
        .append("table")
        .attr("style", `width: ${context.style.width}` );

      if (config.headers) {
        const header = table.append("thead").append("tr");
        header
          .selectAll("th")
          // Get headers from first object column names
          .data((d:any) => config.columns.map((c:string) => d[c]))
          .enter()
          .append("th")
          .text(function(d:any) {
            return d;
          });
      }

      const colStyles = ['', 'text-align:center',  'text-align:right'];
      const tableBody = table.append("tbody");
      const rows = tableBody
        .selectAll("tr")
        .data(data.residents)
        .enter()
        .append("tr");

      // We built the rows using the nested array - now each row has its own array.
      rows.selectAll("td")
      // each row has data associated; we get it and enter it for the cells.
        .data((d:any) => config.columns.map((c:string) => d[c]))
        .enter()
        .append("td")
        .attr( "style",  (d:any, i:number) => {
          return colStyles[i]
        })
        .text((d:any) => {
          return d;
        });
    };

    draw();
  }, [ data, context.config, context.style, context.selector]);

  return renderComponentDiv(context);
};

export default TextTableComponent;
