// NPM
import { useState } from 'react';
import useInterval from '@use-it/interval';
import moment from 'moment-timezone';
import { IDexcomDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { isEmpty, loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  clientId: '',
  clientSecret:'',
  accessToken:'',
  refreshIntervalInMinutes: 5
} as IDexcomDataSourceConfiguration;

// Component
export interface IDexcomEGVS {
  systemTime: Date,
  displayTime: string,
  value: number,
  status: string,
  trend: string,
  trendRate: number,
  realtimeValue: number,
  smoothedValue: number
}

export interface IDexcomEGVSData {
  egvs: IDexcomEGVS[],
  rateUnit: string,
  unit: string,
  lastUpdated: Date

}

export default function useDexcomDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<IDexcomEGVSData>();

  // Initial load after 5 second
  const [delay, setDelay] = useState(5000);
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  useInterval(async () => {
    if (isEmpty(config.clientId) || isEmpty(config.clientSecret) || isEmpty(config.accessToken)) {
      return;
    }

    const CORS_PROXY = 'https://corsproxy.pixon.app/';
    //const now = moment().format(moment.HTML5_FMT.DATE);
    const yesterday = moment('2017-06-17 09:00').format(moment.HTML5_FMT.DATE);
    //const yesterday = moment().subtract(1, 'day').format(moment.HTML5_FMT.DATE);
    const now = moment('2017-06-18').format(moment.HTML5_FMT.DATE);

    //const endDate = moment('2017-06-18').format(moment.HTML5_FMT.DATE);
    const dataUrl = `https://sandbox-api.dexcom.com/v2/users/self/egvs?startDate=${yesterday}&endDate=${now}`;
    console.log('dataurl',dataUrl);

    fetch(CORS_PROXY + dataUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IncyYUVpQmRlMXBfNnNjZmMzXzFpeHFvaDVqTSIsImtpZCI6IncyYUVpQmRlMXBfNnNjZmMzXzFpeHFvaDVqTSJ9.eyJpc3MiOiJodHRwczovL3VhbTEuZGV4Y29tLmNvbS9pZGVudGl0eSIsImF1ZCI6Imh0dHBzOi8vdWFtMS5kZXhjb20uY29tL2lkZW50aXR5L3Jlc291cmNlcyIsImV4cCI6MTYzODcyODc5NywibmJmIjoxNTQ0MTIwNzk3LCJjbGllbnRfaWQiOiJLYlNMcW5xcGJHRVdmR0xua1lwaWxPa2x1ak9FUVBTWSIsInNjb3BlIjpbImVndiIsImNhbGlicmF0aW9uIiwiZGV2aWNlIiwic3RhdGlzdGljcyIsImV2ZW50Iiwib2ZmbGluZV9hY2Nlc3MiXSwic3ViIjoiMDA3OGYzOTItYzJjOC00OTY5LWFjYjctMzllYThkNGM5ODhjIiwiYXV0aF90aW1lIjoxNTQ0MTIwNzg4LCJpZHAiOiJpZHNydiIsImp0aSI6ImFhZWM4OTNhMjM2M2NlOTM2M2VmNTAwY2U4MTdlNTFlIiwiYW1yIjpbInBhc3N3b3JkIl19.Q_TSDdz8qVBjj76nst6SRUlzB3FvaxqNuR_S1o-pFyZ1FsxBLhsJ9-nHAO-GmwW2drPHk9zf985YQcRNTrn3oZ2IvsPuO9bjEZkemtoD3gM5Dyf8lJGIVQXv19ht8j0roBCsVqroT3teol6RwHpPVbU8zGmHGdrcxFhh_xrLiVZ1IjcobqTJP-jsORZU_Vi1cGSNT1sOcDmWCJyJZ3Z81xdJgZaomZryvJJI4ZHDHkvURljFLLwgQHLsDquyiiAVBFNirZ14Qwhj2BYOyvIkoE5v0_bC_lLZgCKR9JMSM05qEQcKIedHGITW_wVykebrdyk_w7XfEhZHs8PY3Do7XA'
      },
    })
      .then((response) => response.json())
      .then((d) => {
        console.log('Success:', d);
        const typedData = d as IDexcomEGVSData;
        setData({
          ...typedData,
          lastUpdated:  moment().toDate()
        });

      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // Refetch at configured interval, never quicker than 5 minutes
    setDelay(1000 * 60 * Math.max(5, config.refreshIntervalInMinutes));
  }, delay);

  return [data];
}
