// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import { PageComponent } from '../graphql/types';
import { RenderContext } from '../services/RenderMachine';
import { CSSPropertiesToString, loadComponent } from '../api/util';
import useDeviceMessageDataSource from '../datasources/DeviceMessageDataSource';
import { renderComponentDiv } from '../views/DeviceComponent';
import renderTemplate from '../api/renderTemplate';

// Defaults
const defaultConfig = {
};

const defaultStyle = {
} as CSSProperties;

// Component
export interface IDeviceMessageComponentProps {
  pageComponent: PageComponent,
  renderContext: RenderContext
}

const clear = (selector: string) => d3.select(selector).html('');

const DeviceMessageComponent = (props: IDeviceMessageComponentProps) => {
  const { pageComponent, renderContext } = props;
  const component = pageComponent.component!;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const [data] = useDeviceMessageDataSource(renderContext, component.dataSource);

  useEffect(() => {
    // Clear drawing
    clear(context.selector);

    const draw = async () => {
      if (!context || !data) return;

      if (context.template)
      {
        let parsedData = data;
        try {
          parsedData = JSON.parse(data.message);
        } catch {

        }

        const output = await renderTemplate(context.template, parsedData);

        d3.select(context.selector)
          .html(output)
          .attr("style", CSSPropertiesToString(context.style));

      } else {

        d3.select(context.selector)
          .append("p")
          .text(data.message ? data.message : '');

        d3.select(context.selector)
          .append("p")
          .text(data.timestamp ? data.timestamp.toISOString() : '');
      }
    };

    draw();
  }, [context, data]);

  return renderComponentDiv(context);
};

export default DeviceMessageComponent;
