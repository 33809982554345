// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';

// Local
import {
  LunchWeek, PageComponent,
  useParkgardenWeekLunchQuery,
} from '../graphql/types';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

// Defaults
const defaultConfig = {
  pollIntervalInMinutes: 5,
};

const defaultStyle = {
  color: 'white',
} as CSSProperties;

// Assets
export interface ITextParkgardenLunchComponentProps {
  pageComponent: PageComponent,
}

// Render device machine state
const TextParkgardenLunchComponent = (props: ITextParkgardenLunchComponentProps) => {
  const {
    pageComponent,
  } = props;
  const component = pageComponent.component!;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));
  const { data, loading, error } = useParkgardenWeekLunchQuery({ pollInterval: 1000 * 60 * context.config.pollIntervalInMinutes });

  const getTodaysLunch = (lunchWeek: LunchWeek) => {
    let dayNumber = (new Date()).getDay() - 1; // 0 = sunday
    if (dayNumber < 0) {
      dayNumber = 6;
    }
    return lunchWeek.days[dayNumber]!;
  };

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    if (!data)
      return;

    const lunchWeeks = data.scrapers.nassjokommun.weekLunchMenus as LunchWeek[];

    if (!lunchWeeks || lunchWeeks.length < 1)
      return;

    const draw = (lunchWeek: LunchWeek) => {
      if (!lunchWeek) return;

      const today = getTodaysLunch(lunchWeek);
      const ul = d3.select(context.selector)
        .append('ul');

      if (today.lunch) {
        ul
          .append('li')
          .text(today.lunch);
      }

      if (today.alternative) {
        ul
          .append('li')
          .text(today.alternative);
      }

      if (today.dessert) {
        ul
          .append('li')
          .text(today.dessert);
      }
    };

    draw(lunchWeeks[0]);
  }, [component, data, context.selector]);

  if (loading || error)
    return null;

  return renderComponentDiv(context);
};

export default TextParkgardenLunchComponent;
