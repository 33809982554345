import { assign, Machine } from 'xstate';
import iotConfigurationMachine from './IotConfigurationMachine';
import configuredMachine from './ConfiguredMachine';

// The hierarchical (recursive) schema for the states
export interface RootDeviceMachineSchema {
  states: {
    uninitialized: {};
    start: {};
    configuring: {};
    configured: {};
  };
}

export interface RootDeviceContext {
  showDebugPanel: boolean
}

// The events that the machine handles
export type RootDeviceEvent =
  | { type: 'REFRESH_DEVICE_CONTEXT'; data: any; }
  | { type: 'TOGGLE_DEBUG_PANEL'; data: any; }
  | { type: 'RESET'; data: any; };

const deviceRootMachine = Machine<RootDeviceContext, RootDeviceMachineSchema, RootDeviceEvent>({
  key: 'root',
  initial: 'uninitialized',
  context: {
    showDebugPanel: false
  },
  states: {
    uninitialized: {
      on: {
        '': 'start'
      }
    },

    start: {
      on: {
        '': [
          // if we already have a persisted config, start render machine
          { target: 'configured', cond: 'haveIotConfig' },
          // otherwise initialize configuration
          { target: 'configuring', cond: 'doesNotHaveIotConfig' },
        ],
      },
    },

    // The configuring state invokes the iotconfigurationmachine and retrieves
    // the iotconfiguration when the machine have completed
    configuring: {
      on: {
        TOGGLE_DEBUG_PANEL: {
          actions: 'toggleDebugPanel'
        }
      },
      invoke: {
        id: 'configurator',
        src: iotConfigurationMachine,
        // The onDone transition will be taken when the
        // iotConfigurationMachine has reached its top-level final state.
        onDone: 'configured'
      }
    },

    // a configured machine is a parallell machine that
    // renders and communicates at the same time
    configured: {
      on: {
        RESET: 'start',
        TOGGLE_DEBUG_PANEL: {
          actions: 'toggleDebugPanel'
        }
      },
      invoke: {
        id: 'configuredMachine',
        src: configuredMachine,
        onDone: 'start'
      }
    }

  },
}, {
  actions: {
    toggleDebugPanel: assign<RootDeviceContext, RootDeviceEvent>({
      showDebugPanel: (context, event) => {
        return event.data.visible;
      }
    })
  },

  // Guard methods to prevent faulty transitions
  guards: {
    haveIotConfig: (context) => localStorage.getItem('iotConfig') !== null,
    doesNotHaveIotConfig: (context) => localStorage.getItem('iotConfig') === null,
  }

});

export default deviceRootMachine;
