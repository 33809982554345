// NPM
import { Guid } from 'guid-typescript';

// Local
import { IoTConfig, IotConfigurationEvent } from './IotConfigurationMachine';
import { graphQlClient } from '../api/api';
import {
  CompleteDeviceLinkDocument,
  CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables,
  CreateDeviceLinkTicketDocument,
  CreateDeviceLinkTicketMutation,
  CreateDeviceLinkTicketMutationVariables,
} from '../graphql/types';

/////////////////////////////////////////////
// ACTIONS
/////////////////////////////////////////////
export const getDeviceTempId = (): string => {
  // Do we have a temp id stored since before?
  let deviceTempId = localStorage.getItem('deviceTempId');

  // Create a new device temp id
  if (!deviceTempId) {
    deviceTempId = Guid.create().toString();
    localStorage.setItem('deviceTempId', deviceTempId);
  }

  return deviceTempId;
};

export  const createIotConfig = (event: IotConfigurationEvent): IoTConfig =>
{
  const deviceCredentials = event.data.data.completeDeviceLink.deviceCredentials;
  return {
    hubHostname: deviceCredentials.ioTHost,
    organizationId: deviceCredentials.organizationId,
    deviceId: deviceCredentials.deviceId,
    deviceIotId: deviceCredentials.deviceIoTId,
    key: deviceCredentials.deviceIoTKey,
    topic: 'devices/' + deviceCredentials.deviceIoTId + '/messages/devicebound/#',
    publishTopic: 'devices/' + deviceCredentials.deviceIoTId + '/messages/events/'
  };
};

/////////////////////////////////////////////
// SERVICES
/////////////////////////////////////////////
export const fetchLinkCode = (deviceTempId: string) =>
  graphQlClient.mutate<CreateDeviceLinkTicketMutation, CreateDeviceLinkTicketMutationVariables>({
    mutation: CreateDeviceLinkTicketDocument,
    variables: {
      input: {
        id: deviceTempId,
      },
    },
  });

export const fetchIotConfig = (deviceTempId: string, linkCode: string) =>
  graphQlClient.mutate<CompleteDeviceLinkMutation, CompleteDeviceLinkMutationVariables>({
    mutation: CompleteDeviceLinkDocument,
    variables: {
      input: {
        deviceTempId: deviceTempId,
        linkCode: linkCode,
      },
    },
  });
