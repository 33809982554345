import { assign, Machine, send, sendParent } from 'xstate';
import renderMachine from './RenderMachine';
import iotConnectionMachine from './ConnectionMachine';

// The hierarchical (recursive) schema for the states
export interface ConfiguredMachineSchema {
  states: {
    render: {};
    communicate: {};
    handleEvent: {};
  };
}

export interface ConfiguredMachineContext {
  isConnected: boolean;
}

// The events that the machine handles
export type ConfiguredMachineEvent =
  | { type: 'RECONNECT'; data: any; }
  | { type: 'CONNECT'; data: any; }
  | { type: 'REFRESH_DEVICE_CONTEXT'; data: any; }
  | { type: 'TOGGLE_DEBUG_PANEL'; data: any; }
  | { type: 'TOGGLE_STATUSBAR'; data: any; }
  | { type: 'FORWARD_COMPONENT_MESSAGE'; data: any; }
  | { type: 'CLEAR_CONFIGURATION'; data: any; };


const configuredMachine = Machine<ConfiguredMachineContext, ConfiguredMachineSchema, ConfiguredMachineEvent>({
  id: 'configured',
  // A parallel machine is in all states at the same time
  type: 'parallel',
  context: {
    isConnected: false
  },
  states: {
    render: {
      invoke: {
        id: 'renderer',
        src: renderMachine,

        // In practice, the rendering should never ever reach
        // end. should we log an error here instead perhaps?
        onDone: {
          actions: sendParent('RESET')
        }
      },
    },

    communicate: {
      invoke: {
        id: 'connectionHandler',
        src: iotConnectionMachine,

        // Communication should be permanent. But if the
        // iotConfig is invalid, the communication machine will exit.
        // This should exit the configured machine as well and restart the
        // configuration process of the device
        onDone: {
          target: 'communicate',
          actions:  [(context, event) => {
              if (event.data.isError)
              {
                // Exit was abnormal -> reset machine
                sendParent('RESET');
              } else {
                // Exit was due to intermittent connection problem -> reconnect
                send('RECONNECT', {delay: 5000})
              }
          }, 'setDisconnected'],
        }
      }
    },

    // Since this machine is a parallell machine, it means
    // that is is in all states as the same time,
    // therefore its possible to handle all events sent from children
    // in all states. Breakout the event handling here for clarity to separate
    // it from the invocations
    handleEvent: {
      on: {
        CLEAR_CONFIGURATION: {
          actions: [
            'clearConfig',
            sendParent('RESET')
          ]
        },
        TOGGLE_STATUSBAR: {
          actions: 'forwardToRenderer'
        },
        REFRESH_DEVICE_CONTEXT: {
          actions: 'forwardToRenderer'
        },
        FORWARD_COMPONENT_MESSAGE: {
          actions: 'forwardToRenderer'
        },
        TOGGLE_DEBUG_PANEL: {
          actions: 'forwardToParent'
        },
        CONNECT: {
          actions: 'setConnected'
        },
        // Restart connection machine after 5s
        RECONNECT: {
          target: 'communicate',
          actions: (c) => console.log('reconnecting')
        }
      }
    }
  },
}, {
  actions: {
    clearConfig: (context, event) => {
        localStorage.clear();
    },
    forwardToRenderer: send<ConfiguredMachineContext, ConfiguredMachineEvent>((ctx,e) => (e), { to: 'renderer'}),
    forwardToParent: sendParent<ConfiguredMachineContext, ConfiguredMachineEvent>((ctx,e) => (e)),
    setDisconnected: assign<ConfiguredMachineContext, ConfiguredMachineEvent>({ isConnected: false }),
    setConnected: assign<ConfiguredMachineContext, ConfiguredMachineEvent>({ isConnected: true })
  },
  guards: {

  }
});

export default configuredMachine;
