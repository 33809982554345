// NPM
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone/moment-timezone';
import { IClockDataSourceConfiguration } from 'pixon-component-core';

// Local
import { DataSource } from '../graphql/types';
import { loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  tickIntervalInMs: 1000,
  timeZone: 'America/Los_Angeles'
} as IClockDataSourceConfiguration;

// Component
export interface IClockData {
  currentTime: Moment
}

export default function useClockDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<IClockData>();
  const config = loadDataSourceConfig(dataSource, defaultConfig);

  useEffect(() => {
    if (!config) return;

    const tick = () => {

      setData({
        currentTime: moment.tz(new Date(), config.timeZone)
      });
    };

    const timerID = setInterval(() => tick(), config.tickIntervalInMs);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [config]);

  return [data];
}
