import { assign, Machine } from 'xstate';
import { fetchDeviceContext } from './RenderService';
import { DeviceContext } from '../graphql/types';
import { readDeviceId } from '../api/util';

// The hierarchical (recursive) schema for the states
interface RenderMachineSchema {
  states: {
    render: {};
    fetchDeviceContext: {};
    fetchContextError: {};
  };
}

export interface RenderContext {
  showStatusBar: boolean,
  context?: DeviceContext,
  componentMessage: any
}

const defaultRenderContext =  {
  showStatusBar: false,
  context: undefined
} as RenderContext;

// The events that the machine handles
type RenderEvent =
  | { type: 'REFRESH_DEVICE_CONTEXT'; data: any; }
  | { type: 'TOGGLE_STATUSBAR'; data: any; }
  | { type: 'FORWARD_COMPONENT_MESSAGE'; data: any; }
  | { type: 'START_RENDER'; data: any; };

const renderMachine = Machine<RenderContext, RenderMachineSchema, RenderEvent>({
  key: 'renderMachine',
  initial: 'fetchDeviceContext',
  context: defaultRenderContext,
  states: {
    // Retrieve a link code to show on display
    fetchDeviceContext: {
      invoke: {
        id: 'fetchDeviceContext',
        src: context => fetchDeviceContext(readDeviceId()!),
        onDone: { target: 'render', actions: 'assignContext' },
        onError: { target: 'fetchContextError' }
      },
    },

    render: {
      on: {
        TOGGLE_STATUSBAR: {
          actions: 'toggleStatusbar'
        },
        FORWARD_COMPONENT_MESSAGE: {
          actions: 'setComponentMessage'
        },
        REFRESH_DEVICE_CONTEXT: 'fetchDeviceContext'
      },

      // Refresh context every 15 minutes, does this cache?
      after: {
        900000: 'fetchDeviceContext'
      }

    },

    fetchContextError: {
      after: {
        10000: 'fetchDeviceContext'
      }

    }

  },
}, {
  actions: {
    assignContext: assign<RenderContext, RenderEvent>({
      context: (context, event) => {
        return event.data.data.deviceContext
      }
    }),
    toggleStatusbar: assign<RenderContext, RenderEvent>({
      showStatusBar: (context, event) => {
        return event.data.visible;
      }
    }),
    setComponentMessage: assign<RenderContext, RenderEvent>({
      componentMessage: (context:RenderContext, event:RenderEvent) => {
        return event.data;
      }
    }),
  },

  // Guard methods to prevent faulty transitions
  guards: {

  }

});

export default  renderMachine;
