// NPM
import { useEffect, useState } from 'react';
import { IRegistryDataSourceConfiguration } from 'pixon-component-core';

// Local
import { Asset, DataSource } from '../graphql/types';
import { getAssetUrl, http, loadDataSourceConfig } from '../api/util';

// Defaults
const defaultConfig = {
  surveyNumberStartsWith: undefined,
  residentType: 'Person',
  sortColumn: 'name',
} as IRegistryDataSourceConfiguration;

// Component
export interface Tenant {
  entrance: string;
  floor: number;
  name: string;
  apartment_number: string,
  national_land_survey_number: string
  resident_type: 'Person' | 'Företag';
}

export interface IResidentRegistryData {
  residents: Tenant[]
}

export default function useResidentRegistryDataSource(dataSource?: DataSource | null) {
  const [data, setData] = useState<IResidentRegistryData>();
  const [config] = useState(loadDataSourceConfig(dataSource, defaultConfig));

  const asset = dataSource
  && dataSource.assets
  && dataSource.assets.length > 0
    ? dataSource.assets[0] as Asset
    : undefined;

  useEffect(() => {
    if (!config || !asset) {
      return;
    }

    const compareBySortColumn = (a: any, b: any) => {
      if (a[config.sortColumn] < b[config.sortColumn]) {
        return -1;
      }
      if (a[config.sortColumn] > b[config.sortColumn]) {
        return 1;
      }
      return 0;
    };

    const filterByNationalLandSurveyNumberAndType = (o: any, filter: string, residentType: string) => {
      return o.national_land_survey_number.startsWith(filter) && o.resident_type === residentType;
    };

    const fetchData = async () => {
      return await http(getAssetUrl(asset));
    };

    fetchData().then(result => {
      if (!result)
        return;

      const sortedAndFiltered =
        result
          .filter((o: any) =>
            filterByNationalLandSurveyNumberAndType(
              o,
              config.surveyNumberStartsWith!,
              config.residentType))
          .sort(compareBySortColumn);

      setData({ residents: sortedAndFiltered });
    });
  }, [asset, config]);

  return [data];
}
