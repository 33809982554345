// NPM
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory as createHistory } from 'history'

// Local
import { PixonApiProvider } from './api/api';
import { ai } from './services/TelemetryService';

// Assets
import './assets/css/index.css';
import App from './App';
import CacheBuster from './CacheBuster';

// Initialize application insights
const history = createHistory({ basename: '' });
ai.initialize({ history: history });

ReactDOM.render(
    <>
      <CacheBuster />
      <PixonApiProvider baseUrl={window.API_BASE_URL}>

        <App />
      </PixonApiProvider>
    </>
  ,
  document.getElementById('root'));
