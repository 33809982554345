// NPM
import useInterval from '@use-it/interval';
import { ai } from './services/TelemetryService';

const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }

  // delete browser cache and hard reload
  window.location.reload();
};

const CacheBuster = () => {
  // Check for new versions once every 30 minutes
  const versionCheckInterval = 1000*60*30;

  useInterval(() => {
    // Your custom logic here
    fetch('/' + process.env.REACT_APP_CONFIG_PATH)
      .then((response) => response.text())
      .then((cfg) => {
        const latestHash = cfg.match(/window.COMMIT_HASH = "([\w\d.-]*)";/)![1];
        const currentHash = window.COMMIT_HASH;

        const shouldForceRefresh = latestHash !== currentHash;
        if (shouldForceRefresh) {
          ai.trackTrace('Found new version, forcing refresh', {
            'currentHash':currentHash,
            'latestHash':latestHash
          });
          refreshCacheAndReload();
        }
      });
  }, versionCheckInterval);

  return null;
};

export default CacheBuster;


