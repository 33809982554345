// NPM
import React from 'react';
import { Interpreter, State } from 'xstate';

// Local
import { RootDeviceContext, RootDeviceEvent, RootDeviceMachineSchema } from '../services/DeviceRootMachine';
import RenderConfiguredMachine from './RenderConfiguredMachine';
import RenderConfiguration from './RenderConfiguration';
import LogoWithSubtitle from '../components/builtin/LogoWithSubtitle';

export interface IRenderRootProps {
  current: State<RootDeviceContext, RootDeviceEvent, RootDeviceMachineSchema>,
  send: Interpreter<RootDeviceContext, any, RootDeviceEvent>['send']
  service: any;
}

// Render device machine state
const RenderRoot = (props: IRenderRootProps) => {
  const { current, send, service } = props;
  const configuredService = service.children.get('configuredMachine');

  if (current.matches('configured')) {
      return configuredService
        ? <RenderConfiguredMachine service={configuredService}/>
        : <LogoWithSubtitle subtitle='Error, state configured without configured service available' />;
  } else {
    return <RenderConfiguration current={current} send={send} service={service}/>
  }
};

export default RenderRoot;
