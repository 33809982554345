// NPM
import { CSSProperties, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { IIframeConfiguration } from 'pixon-component-core';

// Local
import { PageComponent } from '../graphql/types';
import { loadComponent } from '../api/util';
import { renderComponentDiv } from '../views/DeviceComponent';

// Defaults
const defaultConfig = {
  name: 'iframe',
  src: '',
  sandbox: []
} as IIframeConfiguration;

const defaultStyle = {
} as CSSProperties;

// Component
export interface IIframeComponentProps {
  pageComponent: PageComponent
}

const IFrameComponent = (props: IIframeComponentProps) => {
  const { pageComponent } = props;
  const [context] = useState(loadComponent(pageComponent, defaultStyle, defaultConfig));

  useEffect(() => {
    // Clear drawing
    d3.select(context.selector).html('');

    const draw = () => {
      if (!context.config || !context.selector) return;

      d3.select(context.selector)
          .append("iframe")
          .attr("src", context.config.src)
          .attr("name", context.config.name)
          .attr("scrolling", "no")
          .attr("style", "width:100%;height:100%;overflow: hidden;border: 0;")
          .attr("sandbox", d => (context?.config?.sandbox && context.config.sandbox.length > 0)
              ? context.config.sandbox.join(' ')
              : null
          );
    };

    draw();
  }, [context, context.config, context.style, context.selector]);

  return renderComponentDiv(context);
};

export default IFrameComponent;
