// From https://www.carlrippon.com/fetch-with-async-await-and-typescript/
import React, { useContext } from 'react';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../graphql/fragmentTypes.json';
import { ApolloProvider } from '@apollo/react-hooks';
import { DeviceCredentials } from '../graphql/types';

interface PixonApiContext {
  get(path: string): Promise<Response | undefined>;
}

interface PixonApiProviderOptions {
  children: React.ReactElement;
  baseUrl: string;
}

export type Config = DeviceCredentials & {
  topic: string,
  publishTopic: string
}

export const PixonApiContext = React.createContext<PixonApiContext | null>(null);
export const usePixonApi = () => useContext(PixonApiContext)!;

const apiBaseUrl = window.API_BASE_URL;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const httpLink = createHttpLink({
  uri: apiBaseUrl + 'graphql',
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache' as 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache' as 'no-cache',
  },
};

export const graphQlClient = new ApolloClient<InMemoryCache>({
  link: httpLink,
  cache: new InMemoryCache({ fragmentMatcher }) as any,
  defaultOptions: defaultOptions
});

export const PixonApiProvider = ({
                                   children,
                                   baseUrl,
                                   ...initOptions
                                 }: PixonApiProviderOptions) => {


  const get = async (path: string): Promise<Response | undefined> => {
    try {
      return await fetch(apiBaseUrl + path, {
        headers: {
          //Authorization: `Bearer ${accessToken}`
        },
      });
    } catch (error) {
      console.error('Api get error', error);
    }
  };

  return (
    <PixonApiContext.Provider
      value={{
        get,
      }}
    >
      <ApolloProvider client={graphQlClient}>
        {children}
      </ApolloProvider>
    </PixonApiContext.Provider>
  );

};
